import {useChurch} from "./use_church";
import {useEffect, useState} from "react";
import {cacheGet, CacheKey, cacheSet} from "./client_cache";
import * as server_api from "../common/server_api";
import {Hymnal} from "../common/model";

export function useChurchHymnals(): {
  churchHymnals: Hymnal[],
} {
  const {church} = useChurch();
  const [churchHymnals, setChurchHymnals] = useState<Hymnal[]>(cacheGet(CacheKey.ChurchHymnals) ?? []);

  useEffect(() => {
    if (!church) {
      setChurchHymnals([]);
      return;
    }
    void (async () => {
      try {
        const churchHymnalsLocal = await server_api.getChurchHymnals();
        setChurchHymnals(churchHymnalsLocal);
        cacheSet(CacheKey.ChurchHymnals, churchHymnalsLocal);
      } catch {}
    })();
    }, [church]);

  return {churchHymnals};
}