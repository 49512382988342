import React from 'react';
import './footer.css';
import {ReactComponent as LibraryBooks} from '../assets/library-books.svg';
import {ReactComponent as SettingsIcon} from "../assets/settings-outlined.svg";
import {ReactComponent as HelpIcon} from "../assets/question-circle-outlined.svg";
import {ReactComponent as CalendarIcon} from "../assets/calendar-outlined.svg";
import {ReactComponent as OutlinedHeartIcon} from '../assets/heart-outline.svg';
import {isDesktop} from 'react-device-detect';
import {getCurrentPage, getUrlForPage, Pages} from "../util/path";
import {useLocation} from "../util/use_location";
import {isWKWebView} from "../authentication/apple_login";

export interface Props {
  stopSearch?: () => void;
}

export const Footer = (props: Props) => {
  const {stopSearch} = props;
  const {location, navigateTo} = useLocation();
  const page = getCurrentPage(location);

  function getPageNavigator(forPage: Pages) {
    return () => {
      stopSearch?.();
      navigateTo(getUrlForPage(forPage));
    }
  }

  function getIconClassName(forPage: Pages) {
    let sizeClassName: string;
    switch (forPage) {
      case Pages.Library:
        sizeClassName = 'libraryIcon';
        break;
      case Pages.Settings:
        sizeClassName = 'settingsIcon';
        break;
      case Pages.Favorites:
        sizeClassName = 'favoritesIcon';
        break;
      case Pages.Help:
        sizeClassName = 'helpIcon';
        break;
      default:
        sizeClassName = 'footerIcon';
        break;
    }
    return (forPage === page ? 'selectedIcon' : 'unselectedIcon') + ' ' + sizeClassName;
  }

  function getLabelClassName(forPage: Pages) {
    return forPage === page ? 'selectedLabel iconLabel' : 'unselectedLabel iconLabel';
  }

  return (
    <div key='footer' className='footer' style={isWKWebView() || isDesktop? {paddingBottom: 0} : {paddingBottom: 25}}>
      <div key='this-week' className="iconDiv">
        <CalendarIcon className={getIconClassName(Pages.ThisWeek)} onClick={getPageNavigator(Pages.ThisWeek)} />
        <div className={getLabelClassName(Pages.ThisWeek)} onClick={getPageNavigator(Pages.ThisWeek)}>This Week</div>
      </div>
      <div key='library' className="iconDiv">
        <LibraryBooks className={getIconClassName(Pages.Library)} onClick={getPageNavigator(Pages.Library)} />
        <div className={getLabelClassName(Pages.Library)} onClick={getPageNavigator(Pages.Library)}>Library</div>
      </div>
      <div key='favorites' className="iconDiv">
        <OutlinedHeartIcon className={getIconClassName(Pages.Favorites)} onClick={getPageNavigator(Pages.Favorites)} />
        <div className={getLabelClassName(Pages.Favorites)} onClick={getPageNavigator(Pages.Favorites)}>Favorites</div>
      </div>
      <div key='settings' className="iconDiv">
        <SettingsIcon className={getIconClassName(Pages.Settings)} onClick={getPageNavigator(Pages.Settings)} />
        <div className={getLabelClassName(Pages.Settings)} onClick={getPageNavigator(Pages.Settings)}>Settings</div>
      </div>
      <div key='help' className="iconDiv">
        <HelpIcon className={getIconClassName(Pages.Help)} onClick={getPageNavigator(Pages.Help)}/>
        <div className={getLabelClassName(Pages.Help)} onClick={getPageNavigator(Pages.Help)}>Help</div>
      </div>
    </div>
  )
};
