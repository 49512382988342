import * as server_api from "../common/server_api"
import {cacheGet, CacheKey, cacheRemove, cacheSet} from "./client_cache";
import {SongView} from '../common/model';

class SongViews {
  add(songView: SongView) {
    const songViews = cacheGet(CacheKey.SongViews);
    songViews.push(songView);
    cacheSet(CacheKey.SongViews, songViews)
  }

  async uploadToServer(): Promise<void> {
    const songViews = cacheGet(CacheKey.SongViews);
    if (songViews.length === 0) {
      return;
    }
    await server_api.reportSongViews(songViews);
    // clear song views if upload was successful
    // otherwise an exception will be thrown by server_api and we will try again next time
    cacheRemove(CacheKey.SongViews);
  }
}

export const songViews = new SongViews();
