import {useCallback} from 'react';
import {cacheGet, CacheKey, cacheRemove, cacheSet} from './client_cache';
import * as server_api from '../common/server_api';
import {churchFromChurchString} from '../common/server_api';
import {Church} from '../common/model';
import {useCache} from './use_cache';

// synchronizes server setting with client cache

function getHouseholdChurch(
    householdChurchId: number | undefined,
    householdChurchName: string | undefined
): Church | undefined {
  if (householdChurchId === undefined || householdChurchName === undefined) {
    return undefined;
  }
  const {name, location} = churchFromChurchString(householdChurchName);
  return {id: householdChurchId, name, location};
}

export function useChurch(): {
  church: Church | undefined
  setChurch: (church: Church | undefined) => void
} {
  const [church, setChurch] = useCache(CacheKey.Church);

  const setChurchAndPushToServer = useCallback((newChurch: Church | undefined) => {
    if (JSON.stringify(church) === JSON.stringify(newChurch)) {
      return;
    }
    setChurch(newChurch);
    cacheSet(CacheKey.PushChurch, true);
    void synchronizeChurchWithServer();
  }, [church, setChurch]);

  return {church, setChurch: setChurchAndPushToServer};
}

export async function synchronizeChurchWithServer() {
  // ignore network errors
  try {
    // TODO(hewitt): Remove 9/2024 - eradicate old church string cache entries (replace with Church object)
    if (typeof cacheGet(CacheKey.Church) === 'string') {
      cacheRemove(CacheKey.Church);
      cacheRemove(CacheKey.PushChurch);
    }

    const householdToken = cacheGet(CacheKey.BillingHouseholdToken);
    if (!householdToken) {
      cacheRemove(CacheKey.Church);
      cacheRemove(CacheKey.PushChurch);
      return;
    }
    let church = cacheGet(CacheKey.Church);

    // TODO(hewitt): Remove 9/2024 - Migrate from billing church id & church name to household church
    const householdChurchId = cacheGet(CacheKey.HouseholdChurchId);
    const householdChurchName = cacheGet(CacheKey.HouseholdChurchName);
    if (!church && householdChurchId) {
      church = getHouseholdChurch(householdChurchId, householdChurchName);
      cacheSet(CacheKey.Church, church);
    }
    cacheRemove(CacheKey.HouseholdChurchId);
    cacheRemove(CacheKey.HouseholdChurchName);

    if (cacheGet(CacheKey.PushChurch)) {
      await server_api.setHouseholdChurch(householdToken, church);
      cacheRemove(CacheKey.PushChurch);
    } else {
      const serverChurch = await server_api.getHouseholdChurch(householdToken);
      if (JSON.stringify(church) !== JSON.stringify(serverChurch)) {
        cacheSet(CacheKey.Church, serverChurch);
      }
    }
  } catch { }
}
