import React, {useCallback} from "react";
import '../shared.css'
import './request_song_page.css'
import * as server_api from "../common/server_api";
import {useCache} from "../data/use_cache";
import {CacheKey} from "../data/client_cache";
import {useLocation} from "../util/use_location";
import {HymnIssue} from "../common/model";
import {ensureUnreachable} from "../common/util";

export interface Props {
  issue: HymnIssue;
  hymnalName: string;
  songNumber: number;
}

export function RequestSongPage(props: Props) {
  const [credentials] = useCache(CacheKey.Credentials);
  const {navigateTo} = useLocation();

  let displayText;
  switch (props.issue) {
    case HymnIssue.Music:
      displayText = "The copyright holder has not yet granted permission for this song."
      break
    case HymnIssue.Text:
      displayText = "The copyright holder has not yet granted permission for the text."
      break
    case HymnIssue.Missing:
      displayText = "This song has not yet been added."
      break
    default:
      ensureUnreachable(props.issue, 'Failed to cover all HymnIssue values');
  }

  const requestSong = useCallback(() => {
    if (credentials) {
      void server_api.addSongRequest({
        hymnalName: props.hymnalName,
        songNumber: props.songNumber,
        timestamp: Date.now(),
        issue: props.issue,
      });
    }
  }, [credentials, props.hymnalName, props.issue, props.songNumber]);

  return (
    <div className="div">
      <h1 className="requestMessage"><br/>{displayText}<br/></h1>
      {
        credentials ?
          <button className="requestButton" onClick={requestSong}>Request Song</button> :
          <div className="requestMessage">To request this song, please <span className="inlineLink" onClick={() => navigateTo('/settings')}>sign in</span>.</div>
      }
    </div>
  )
}