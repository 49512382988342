import '../shared.css';
import './hymns_list.css';
import React, {Fragment, ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {getHymnFromUrl, hymnalsPath, InnerPageContent, OuterPageContent} from "../shared";
import {Header} from "./header";
import {PlayerPage} from './player_page';
import {ReactComponent as CloseCircleIcon} from "../assets/close-circle.svg";
import {ReactComponent as PlayIcon} from "../assets/play.svg";
import {ReactComponent as SearchIcon} from "../assets/search.svg";
import {runClientTests} from "../client_tests";
import {Footer} from "./footer";
import {Hymn} from "../sequencer";
import {favorites} from "../data/favorites";
import {useCache} from "../data/use_cache";
import {cacheGet, CacheKey, cacheSet, Guest} from "../data/client_cache";
import * as server_api from "../common/server_api"
import {getCurrentPage, getUrlForPage, Pages} from "../util/path";
import {userVisibleSongNumber} from "../common/util";
import {useLocation} from "../util/use_location";
import {
  AppConfig,
  HouseholdStatus,
  Hymnal,
  HymnalManifest,
  HymnalsManifest,
  HymnIssue,
  SongFeature
} from "../common/model";
import {ensureExists} from "../common/util";
import {ReactComponent as VocalsIcon} from "../assets/vocals.svg";
import styled from "styled-components";
import {useUserAttributes} from "../data/use_user_attributes";
import {useChurchHymnals} from "../data/use_church_hymnals";
import {useChurch} from "../data/use_church";
import {OptInButton, SignUpButton} from '../util/sign_up_button';
import {isAppConfigEnabled} from '../data/app_config';
import {synchronizeHouseholdStatus} from '../util/billing';

export const LibraryPageTitle = 'Library';
const MaxSearchResultsPerHymnal = 50;

export interface HymnalWithHymns extends Hymnal {
  hymns: Hymn[];
  message?: JSX.Element;
}

export interface Props {
  title: string;
  weeklySongGroups?: HymnalWithHymns[];
  expandForThisWeek: boolean;
  message?: JSX.Element;
  showFavorites?: boolean;
}

export const HymnsList = (props: Props) => {
  const [expandedHymnals, setExpandedHymnals] = useState<HymnalWithHymns[]>([]);
  const [searchString, setSearchString] = useState('');
  const [hymnals, setHymnals] = useState<Hymnal[]>([]);
  const [publishers, setPublishers] = useState<Record<string, string>>({});
  const [allHymnals, setAllHymnals] = useState<HymnalWithHymns[]>([]);
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const [favoriteHymns, setFavoriteHymns] = useState<Hymn[]>([]);
  const favoritesHymnal = useMemo<HymnalWithHymns>(() => ({id: 0, name: "Favorites", hymns: []}), []);
  const [credentials] = useCache(CacheKey.Credentials);
  const [currentHymnalName, setCurrentHymnalName] = useCache(CacheKey.CurrentHymnalName);
  const {isInternalUser} = useUserAttributes();
  const [showAllHymnals] = useCache(CacheKey.ShowAllHymnals);
  const {churchHymnals} = useChurchHymnals();
  const {church} = useChurch();
  const [user] = useCache(CacheKey.User);
  const [householdStatus] = useCache(CacheKey.HouseholdStatus);

  // state var for whether to display player or not, sort of like a boolean but is a Hymn obj
  // initialize to undefined
  const [playlist, setPlaylist] = useState<Hymn[] | undefined>(undefined);
  const hymnalsManifest = `${hymnalsPath}/manifest3.json`;
  const hymnalManifest = (hymnalName: string) => `${hymnalsPath}/${hymnalName}/manifest3.json`;
  const {location, navigateTo} = useLocation();
  const page = getCurrentPage(location);
  const {search} = location;
  useEffect(() => {
    if (props.expandForThisWeek && props.weeklySongGroups?.length) {
      // For This Week, expand both Sundays
      setExpandedHymnals(props.weeklySongGroups);
    }
  }, [props.expandForThisWeek, props.weeklySongGroups]);
  const hymnalIcon = (hymnalName: string) => `${hymnalsPath}/${hymnalName}/icon.png`;
  const searchInputRef = useRef<HTMLInputElement>(null);

  const churchHymnalIds = churchHymnals.map(hymnal => hymnal.id);
  const visibleHymnals = allHymnals
    .filter(hymnal =>
      (
        churchHymnalIds.includes(hymnal.id) ||
        (
          (showAllHymnals || user === Guest || church === undefined) &&
          (!hymnal.isChurchSpecific || isInternalUser)
        )
      ) && (
        hymnal !== favoritesHymnal
      )
    )

  const singleHymnal = !props.weeklySongGroups && !props.showFavorites && currentHymnalName;
  const title = singleHymnal ? currentHymnalName : props.title;
  const hidePaidContent = !isAppConfigEnabled(AppConfig.SuppressPaywall) &&
    householdStatus !== HouseholdStatus.Subscribed;
  const maxSearchResultsPerHymnal = !singleHymnal && !props.showFavorites
    ? MaxSearchResultsPerHymnal : Number.MAX_SAFE_INTEGER;

  // download hymnals manifest
  useEffect(() => {
    fetch(hymnalsManifest)
      .then((response) => response.json())
      .then((hymnalsManifest: HymnalsManifest) => {
        (async () => {
          const serverHymnals: Hymnal[] = Object.entries(hymnalsManifest)
            .filter(([_name, data]) => isInternalUser || !data.isInternal)
            .map(([name, data]) => ({
              name,
              id: data.id,
              isInternal: data.isInternal,
              isChurchSpecific: data.isChurchSpecific
            }));
          setHymnals(serverHymnals);
          setPublishers(Object.entries(hymnalsManifest).reduce(
            (acc, [hymnalName, entry]) => {
              acc[hymnalName] = entry.publisher;
              return acc;
            }, {} as Record<string, string>)
          );
        })();
      })
  }, [hymnalsManifest, isInternalUser]);

  const countSongNumbers = useCallback((hymnalManifest: HymnalManifest) => {
    return Object.keys(hymnalManifest)
      .map(key => key.match(/^\d+/)?.[0])
      .filter(key => key !== undefined)
      .reduce((prev, cur) => {
        // @ts-ignore (TS not yet smart enough to apply the filter)
        prev[cur] = (prev[cur] || 0) + 1;
        return prev;
      }, {} as {[number: string]: number});
  }, []);

  // download each hymnal
  useEffect(() => {
    if (hymnals.length === 0) {
      return;
    }
    Promise.all(hymnals.map(hymnal => fetch(hymnalManifest(hymnal.name))))
      .then((responses) => Promise.all(responses.map(response => response.json())))
      .then((hymnalManifests: HymnalManifest[]) => {
        const populatedHymnals: HymnalWithHymns[] = hymnalManifests.map((hymnalManifest, i) => {
          const hasSuffix = (number: string) => number.match(/\d+\.\d+/);
          const songNumbers = countSongNumbers(hymnalManifest);
          return {
            ...hymnals[i],
            hymns: Object.entries(hymnalManifest)
              // hide songs without suffixes that match songs with suffixes (e.g. hide 17 if 17.01 and 17.02 exist)
              // leave song in if the .01 is marked "alt." (see CC2020 597 & 597a), in which case the count is 2
              .filter(([number]) => hasSuffix(number) || songNumbers[number] <= 2)
              .map(([number, data]) => ({...data, number: Number(number), hymnal: hymnals[i].name}))
              .sort((lhs, rhs) => lhs.number - rhs.number),
          };
        });
        setAllHymnals([...populatedHymnals, favoritesHymnal]);
        for (const hymnal of populatedHymnals) {
          favorites.registerHymnal(hymnal);
        }
      })
  }, [hymnals, favoritesHymnal, countSongNumbers]);

  // refresh favorites
  useEffect(() => {
    if (!credentials) {
      return;
    }
    setFavoriteHymns(favorites.get());
    const token = favorites.registerCallback(() => {
      setFavoriteHymns(favorites.get());
    });
    void favorites.reconcileWithServer();
    return () => favorites.unregisterCallback(token);
  }, [credentials]);

  // update favorites hymnal
  useEffect(() => {
    if (!credentials) {
      favoritesHymnal.message = <div>Please <span className="inlineLink" onClick={() => navigateTo('/settings')}>sign in</span> to choose favorites.</div>;
    } else if (favoriteHymns.length === 0) {
      favoritesHymnal.message = <div>Open a song to mark it as a favorite</div>;
    } else {
      favoritesHymnal.message = undefined;
    }
    favoritesHymnal.hymns = favoriteHymns;
  }, [favoriteHymns, favoritesHymnal, credentials, navigateTo]);

  function serviceWorkerListener(event: any) {
    if (event.data === 'ReloadPage') {
      window.location.reload();
    }
  }

  useEffect(() => {
    navigator.serviceWorker?.addEventListener('message', serviceWorkerListener);
    return () => navigator.serviceWorker?.removeEventListener('message', serviceWorkerListener);
  }, [])


  useEffect(() => {
    void (async () => {
      if (window.navigator.serviceWorker) {
        try {
          const appHashes = await server_api.getAppHashes();
          const appVersion = cacheGet(CacheKey.AppVersion);
          const hymnalsHash = cacheGet(CacheKey.HymnalsHash);

          if (hymnalsHash !== appHashes.hymnalsHash) {
            cacheSet(CacheKey.HymnalsHash, appHashes.hymnalsHash);
            console.log('Clearing cached hymnals');
            const keys = await caches.keys();
            await Promise.all(keys
              .filter(key => key.includes('hymnals') || key.includes('vocals'))
              .map(key => caches.delete(key))
            );
          }

          if (appVersion !== appHashes.appVersion) {
            cacheSet(CacheKey.AppVersion, appHashes.appVersion);
            const firstTimeClientLoad = !appVersion;
            if (!firstTimeClientLoad) {
              console.log('INITIATING APP UPGRADE');
              // @ts-ignore
              window.navigator.serviceWorker.controller.postMessage('ClearCachesAndReload');
            }
          }
        } catch {
          // ignore network errors - skip upgrade check until network is up
        }
      }
    })();
  }, []);

  // ignore bogus cached hymnal name (e.g. if a hymnal is removed)
  if (currentHymnalName && allHymnals.length > 0 && !allHymnals.some(hymnal => hymnal.name === currentHymnalName)) {
    setCurrentHymnalName(undefined);
  }

  const songList: Hymn[] = []

  function createSongList() {
    let hymnals: HymnalWithHymns[];
    if (props.weeklySongGroups) {
      // For This Week page, only show all hymnals if the customer is subscribed
      hymnals = searchString && !hidePaidContent ? visibleHymnals : props.weeklySongGroups;
    } else if (props.showFavorites) {
      hymnals = [favoritesHymnal];
    } else if (currentHymnalName) {
      hymnals = allHymnals.filter(hymnal => hymnal.name === currentHymnalName);
    } else {
      hymnals = (expandedHymnals.length === 0 && searchString) ? visibleHymnals : expandedHymnals;
    }
    for (const hymnal of hymnals) {
      for (const hymn of getVisibleHymns(hymnal)) {
        if(hymn.issue && (hymn.issue === HymnIssue.Music || hymn.issue === HymnIssue.Missing)) {
          continue;
        }
        songList.push(hymn)
      }
    }
  }

  const searchRegEx = useMemo(() => {
    // if search string starts with nonAlphaNumeric character(s) chop them off
    // also remove all apostrophes (removed from filenames during search as well)
    const searchTrimmed = searchString
      .replace(/^[^a-z\d'’]+/gi, '')
      .replace(/['’]/g, '')
    const spaceRegEx = `[a-z0-9]*[^a-z0-9']+`
    const searchTerms = searchTrimmed.replaceAll(/[^a-z\d'’]+/gi, spaceRegEx)
    return new RegExp(searchTerms, 'gi')
  }, [searchString]);

  function generateSongDisplayName(hymn: Hymn) {
    const displayName = hymn.title;

    const match = displayName.match(searchRegEx)

    const startIndex = displayName.search(searchRegEx)
    const endIndex = startIndex + (match && match.length ? match[0].length : 0)

    const beforeBold = displayName.slice(0, startIndex)
    const bold = displayName.slice(startIndex, endIndex)
    const afterBold = displayName.slice(endIndex, displayName.length)

    return <SongNameWrapper>
      <div>
        {beforeBold}
        <b>{bold}</b>
        {afterBold}
      </div>
      <VocalsIconWrapper $visible={hymn.features?.includes(SongFeature.Vocals)}>
        <VocalsIcon className="vocalsIcon"/>
      </VocalsIconWrapper>
    </SongNameWrapper>
  }

  function generateUrl(hymn: Hymn): string {

    const hymnalName = hymn.hymnal.replace(/ /g, '_');
    const hymnTitle = hymn.title.replace(/ /g, '_');
    const hymnNumber = hymn.number;
    let psalmName;

    if (hymn.psalm) {
      psalmName = hymn.psalm.replace(/ /g, '_');
    } else {
      psalmName = '';
    }

    const url: string =
      getUrlForPage(page ?? Pages.Library) + '?' +
      'hymnal=' + hymnalName +
      '&title=' + hymnTitle +
      '&number=' + hymnNumber.toString() +
      '&psalm=' + psalmName +
      (hymn.issue ? '&issue=' + hymn.issue : '');

    return (url);
  }

  function getVisibleHymns(hymnal: HymnalWithHymns) {
    return hymnal.hymns
      .filter((hymn) => {
        const number = userVisibleSongNumber(hymn.number);
        const psalmSuffix = hymn.psalm && !ensureExists(hymn.title).toLowerCase().includes('psalm') ? ' - ' + hymn.psalm : '';
        const hymnString = number + ' - ' + hymn.title.replace(/['’]/g, '') + psalmSuffix;
        return hymnString.search(searchRegEx) !== -1
      })
  }


  function renderHymnalHeadingAndHymns({
    headingIndex,
    headingCount,
    firstChildIndex,
    hymnal,
    displayHymnalTitle,
  }: {
    headingIndex: number;
    headingCount: number;
    firstChildIndex: number;
    hymnal: HymnalWithHymns;
    displayHymnalTitle: boolean;
  }): (ReactElement | null)[] {
    const visibleHymns = getVisibleHymns(hymnal);
    const nthChild = firstChildIndex + 1;

    function scrollIntoView() {
      // Because headings (This Week, Last Week) are sticky, if we scroll them into view, the scroll calculation
      // will be based on where the header is stuck rather than where it currently resides in the scrolled list,
      // causing it not to scroll into the correct location.
      // For example, say the header is stuck to the bottom, but its current position is actually off the bottom
      // of the screen.  When we scroll it into view, the scroll calculation will determine the distance from its
      // stuck location to the desired scroll location and scroll by that amount.  This will not scroll far enough.
      // Instead, it should have calculated based on the distance from where it currently resides in the scrolled
      // list, which is off the bottom of the screen.
      // So, we scroll based on the first child of the header, which is not sticky, and therefore always scrolls to the
      // correct location on the screen, causing the header to also be positioned correctly.
      // Both Safari & Chrome exhibit the same behavior - not sure about other browsers, but don't really care.
      const firstHymnId = (nthChild + 1).toString() + '-hymn';
      document.getElementById(firstHymnId)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }

    if (hymnal.message) {
      return [
        <HymnalHeading
          key={hymnal.name + '-heading'}
          hidden={props.showFavorites}
          $headingIndex={headingIndex}
          $headingCount={headingCount}
          $nthChild={nthChild}
          onClick={scrollIntoView}
        >
          <HymnalNameRow colSpan={100}>
            <HymnalNameGroup>
              <HymnalName>{hymnal.name}</HymnalName>
            </HymnalNameGroup>
          </HymnalNameRow>
        </HymnalHeading>,
        <tr key={hymnal.name + '-message'}>
          <HymnalMessage colSpan={100}>{hymnal.message}</HymnalMessage>
        </tr>
      ];
    }

    function wrapHymnalName(hymnalName: string): ReactElement {
      let hymnalNameParts: ReactElement[];
      if (!hymnalName.includes('-')) {
        hymnalNameParts = [<HymnalName>{hymnalName}</HymnalName>];
      } else {
        const parts = hymnalName.split('-');
        hymnalNameParts = [
          <HymnalName key='hymnalName'>{parts[0].trim()}</HymnalName>,
          <HymnalNameSecondLine key='hymnalNameSecondLine'>{parts[1].trim()}</HymnalNameSecondLine>
        ];
      }
      return (
        <HymnalNameRow colSpan={100}>
          <HymnalNameGroup>
            {hymnalNameParts}
          </HymnalNameGroup>
        </HymnalNameRow>
      );
    }

    const tableRows = Array.from(visibleHymns.slice(0, maxSearchResultsPerHymnal + 1).entries())
      .map(([index, hymn]) => {
        const url = generateUrl(hymn);

        const openSong = () => handleClick(hymn);
        const thisRowNthChild = nthChild + index + 1;
        const hymnId = thisRowNthChild.toString() + '-hymn';

        if (index === maxSearchResultsPerHymnal) {
          return <HymnRow key='ellipsis'><td /><EllipsisCell colSpan={100}>...</EllipsisCell></HymnRow>;
        }

        const numberCell = (
          <td key='songNumber' className='number songLink' onClick={() => navigateTo(url)}>
            {userVisibleSongNumber(hymn.number)}
          </td>
        )

        const titleCell = (
          <td key='songName' className='songName songLink' colSpan={hymn.psalm ? 1 : 2} onClick={() => navigateTo(url)}>
            {generateSongDisplayName(hymn)}
          </td>
        )

        const psalmCell = (
          <td key='psalm' className='psalm songLink'  onClick={() => navigateTo(url)}>
            {hymn.psalm && hymn.psalm.substring(0, 2) + '.' + hymn.psalm.substring(5)}
          </td>
        )

        const key = hymn.number + ' ' + hymn.title;
        const copyrightedClassname = hymn.issue && hymn.issue !== HymnIssue.Text ? 'copyrightedSong' : '';
        const content = [
          numberCell,
          titleCell,
          hymn.psalm ? psalmCell : null
        ];

        // It is very expensive to utilize a custom styled component for every row in the hymnal when only the
        // first hymn after the heading is used for scrolling.  Thus, we only use a non-custom styled component (below)
        // for all the rest of the hymns.
        if (index === 0) {
          return (
            <ScrollableHymnRow
              key={key}
              id={hymnId}
              onClick={() => openSong()}
              className={copyrightedClassname}
              $headingIndex={headingIndex}
              $nthChild={thisRowNthChild}
            >
              {content}
            </ScrollableHymnRow>
          );
        }
        return (
          <HymnRow key={key} id={hymnId} onClick={() => openSong()} className={copyrightedClassname}>
            {content}
          </HymnRow>
        )
      });

    const hymnalHeading = !displayHymnalTitle ?
      null :
      <HymnalHeading
        key={hymnal.name + '-heading'}
        $headingIndex={headingIndex}
        $headingCount={headingCount}
        $nthChild={nthChild}
        onClick={scrollIntoView}
      >
        {wrapHymnalName(hymnal.name)}
      </HymnalHeading>;

    return [hymnalHeading, ...tableRows];
  }

  const cancelSearch = useCallback(() => {
    setSearchString('');
  }, []);

  // todo: correct type for keyboard event
  const onSearchKeyUp = useCallback((e: any /*KeyboardEvent*/) => {
    if (e.key === 'Escape') {
      cancelSearch();
      searchInputRef.current?.blur();
    }
  }, [cancelSearch]);

  function renderSearchControl() {
    // allow search on other pages like This Week
    if (hidePaidContent && title === LibraryPageTitle) {
      return null;
    }
    const showPlayButton = !isFancyHymnalListVisible();
    const widthClassName = showPlayButton ? 'searchPartialWidth' : 'searchFullWidth';
    return (
      <div key='search' id="subHeader">
        <table id="searchTable" className={widthClassName}>
          <tbody key='searchTable'>
            <tr key='searchRow'>
              <td key='searchIcon' className="searchIconCell">
                <div className="searchIcon">
                  <SearchIcon/>
                </div>
              </td>
              <td key='searchDiv' className="searchCell">
                <input
                  key='search'
                  id='song-search-bar'
                  type='text'
                  placeholder='Search'
                  onChange={(event) => setSearchString((event.target as HTMLInputElement).value)}
                  onFocus={() => setTimeout(() => setSearchHasFocus(true))}
                  onBlur={() => setTimeout(() => setSearchHasFocus(false))}
                  onKeyUp={onSearchKeyUp}
                  autoComplete='off'
                  className='searchInput'
                  value={searchString}
                  ref={searchInputRef}
                />
                {/* Note that the built-in search cancel button does not work on iOS */}
              </td>
              <td key='searchCancel' className='cancelSearchCell'>
                <div
                  hidden={!searchHasFocus && searchString === ''}
                  key='cancelSearch'
                  onClick={cancelSearch}
                >
                  <CloseCircleIcon className='cancelSearchIcon'/>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="playAllButton" hidden={!showPlayButton}>
          <PlayIcon onClick={() => loadList()}/>
        </div>
      </div>
    );
  }

  const scrollableDiv = useRef<HTMLDivElement>(null);

  function resetScrollPosition() {
    if (scrollableDiv.current) {
      scrollableDiv.current.scrollTop = 0;
    }
  }

  function renderFancyHymnalList() {
    return (
      <div>
        <table key="fancyHymnalList" className="hymnalList">
          <tbody key="fancyHymnalListBody">
          {
            visibleHymnals
              .map(({name}, index) =>
                <Fragment key={name}>
                  <tr key={name} className="hymnal" onClick={() => {
                    resetScrollPosition();
                    setCurrentHymnalName(name);
                  }}>
                    <td key="icon" className="hymnalIconContainer">
                      <img
                        className={'hymnalIcon' + (name === 'Tanglewood Hymnal' ? ' hymnalIconBorder' : '')}
                        src={hymnalIcon(name)}
                        alt="hymnal icon"
                      />
                    </td>
                    <td key="description" className="hymnalDescription">
                      <div key="title" className="hymnalTitle">{name}</div>
                      <div key="publisher" className="hymnalPublisher">{publishers[name]}</div>
                    </td>
                  </tr>
                  {
                    index === hymnals.length - 1 ?
                      null :
                      <tr key={name + '-divider'}>
                        <td key="empty" />
                        <td key="divider">
                          <hr className="hymnalDivider" />
                        </td>
                      </tr>
                  }
                </Fragment>
              )
          }
          </tbody>
        </table>
        {showAllHymnals || churchHymnalIds.length === 0 ? undefined :
          <ShowAllHymnalsMessage>
            To view all hymnals,<br/>enable "Show All Hymnals" in <span className="inlineLink" onClick={() => navigateTo('/settings')}>Settings</span>
          </ShowAllHymnalsMessage>
        }
      </div>
    );
  }

  function isFancyHymnalListVisible() {
    return !props.weeklySongGroups && !searchString && !currentHymnalName && !props.showFavorites;
  }

  function renderHymnals() {
    if (isFancyHymnalListVisible()) {
      return renderFancyHymnalList();
    }

    createSongList()

    let hymnals: HymnalWithHymns[];
    let displayHymnalTitle = true;
    // TODO(hewitt): eliminate duplicated logic here
    if ((!searchString || hidePaidContent) && props.weeklySongGroups) {
      hymnals = props.weeklySongGroups;
    } else if (props.showFavorites) {
      hymnals = [favoritesHymnal];
      displayHymnalTitle = false;
    } else if (currentHymnalName && !props.weeklySongGroups) {
      hymnals = allHymnals.filter(hymnal => hymnal.name === currentHymnalName);
      displayHymnalTitle = false;
    } else {
      hymnals = visibleHymnals;
    }

    const hymnalsToRender = hymnals
        .map(hymnal => ({...hymnal, hymns: getVisibleHymns(hymnal)}))
        .map(hymnal => ({...hymnal, message: hymnal.hymns.length === 0 ? hymnal.message : undefined}))
        .filter(hymnal => hymnal.hymns.length > 0 || hymnal.message);
    let childCounter = 0;

    return [(
      <table key='this-week-table' className='this-week-table'>
        <tbody key='this-week-body' className='this-week-table-body'>
          {
            Array.from(hymnalsToRender.entries()).map(([headingIndex, hymnal]) => {
              const result = renderHymnalHeadingAndHymns({
                headingIndex,
                headingCount: hymnalsToRender.length,
                firstChildIndex: childCounter,
                hymnal,
                displayHymnalTitle,
              });
              const headerCount = displayHymnalTitle ? 1 : 0;
              const ellipsisCount = 1;
              const messageCount = 1;
              childCounter += hymnal.hymns.length > 0
                ? Math.min(hymnal.hymns.length + headerCount, maxSearchResultsPerHymnal + ellipsisCount + headerCount)
                : messageCount + headerCount;
              return result;
            })
          }
        </tbody>
      </table>
    ),
      // advertise subscription during search on This Week page
      searchString && hidePaidContent ? <FooterSubscriptionInvitation marginTop={20}/> : undefined
    ];
  }

  const onBack = useCallback(() => {
    setPlaylist(undefined);
    if (getHymnFromUrl(search)) {
      window.history.back();
    }
  }, [setPlaylist, search]);

  function handleClick(hymn: Hymn) {
    setPlaylist([hymn]);
  }

  function loadList() {
    if (songList.length > 0) {
      setPlaylist(songList)
    }
  }

  // When the browser navigates back to the song list from a single song,
  // the search string is cleared, but the playlist still contains a single song.
  // If the playlist contains multiple songs, it is not possible to navigate
  // back in the browser because we do not navigate to a new URL.
  // This is because the single song attributes are passed through the URL ,
  // but multiple songs are passed to the PlayerPage via the "playlist" prop
  const navigatedBackToHymnsList = playlist &&
    playlist.length === 1 && !new URLSearchParams(search).get('title');

  if (playlist && !navigatedBackToHymnsList) {
    return (
      <PlayerPage hymns={playlist} onBack={onBack} />
    );
  }

  //if there are search parameters, set state to search param hymn
  //meaning on refresh it immediately navigates back to song you were just on
  //using the contents of the browser search bar

  if (getHymnFromUrl(search)) {
    setPlaylist(getHymnFromUrl(search));
  }

  const searchInputElement = document.getElementById('song-search-bar')
  const hymnsTableElement = document.getElementById('song-list')
  hymnsTableElement?.addEventListener("touchmove", () => searchInputElement?.blur())
  const onHymnalBack = singleHymnal ? () => {
    resetScrollPosition();
    setCurrentHymnalName(undefined)
  } : undefined;

  return (
    <OuterPageContent>
      {searchHasFocus || (searchString !== '') ? undefined : (
        <Header title={title} onBack={onHymnalBack}/>)}
      {renderSearchControl()}
      <div key='run-tests'
           hidden={window.location.hostname !== "localhost" && !window.location.hostname.startsWith("192.")}>
        <button onClick={runClientTests}>Run Tests</button>
      </div>
      {
        hidePaidContent && title === LibraryPageTitle ?
          (
            <FooterSubscriptionInvitation marginTop={50}/>
          ) : (
            <InnerPageContent key='inner' id="song-list" ref={scrollableDiv}>
              {
                (!props.message || allHymnals.length > 0) ? renderHymnals() : null
              }
              {
                (props.message && !searchHasFocus && searchString === '') ? (
                  <div key='message' className='message'>
                    {props.message}
                  </div>
                ) : null
              }
            </InnerPageContent>
          )
      }
      <Footer stopSearch={() => setSearchString('')}/>
    </OuterPageContent>
  );
}

const SongNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2px;
`;

const VocalsIconWrapper = styled.div<{ $visible?: boolean; }>`
  display: ${props => props.$visible ? 'inherit' : 'none'};
  flex-shrink: 0;
`;

const ShowAllHymnalsMessage = styled.div`
  margin-top: 2rem;
`;

function FooterSubscriptionInvitation({marginTop}: {marginTop: number}) {
  const {church} = useChurch();
  const [householdToken] = useCache(CacheKey.BillingHouseholdToken);
  const displayOptIn = householdToken && church?.token && church?.areHouseholdsSubscribed;
  const message = displayOptIn
    ? 'Your church provides full subscriptions to all members.'
    : 'Subscribe your household to access all hymnals.';
  const onOptIn = useCallback(async () => {
    await server_api.optHouseholdIntoChurchSubscription({
      householdToken: ensureExists(householdToken),
      churchToken: ensureExists(church?.token),
    });
    await synchronizeHouseholdStatus();
  }, [householdToken, church]);
  return (
    <SubscribeContainer $marginTop={marginTop}>
      <SubscribeMessage $maxWidthCh={displayOptIn ? 21 : 20}>{message}</SubscribeMessage>
      {displayOptIn ? <OptInButton onClick={onOptIn}/> : <SignUpButton/>}
    </SubscribeContainer>
  )
}

const SubscribeContainer = styled.div<{$marginTop: number}>`
  margin: ${props => props.$marginTop}px auto;
  font-size: 1.3em;
  text-wrap: wrap;
  height: 100%;
`

const SubscribeMessage = styled.div<{$maxWidthCh: number}>`
  padding-bottom: 20px;
  max-width: ${props => props.$maxWidthCh}ch;
`
const HymnalHeading = styled.tr<{
  $headingIndex: number;
  $headingCount: number;
  $nthChild: number;
}>`
  font-family: Jost-SemiBold, "Arial", sans-serif;
  font-size: x-large;
  text-align: left;
  position: sticky;
  top: 0;
  background-color: white;
  cursor: pointer;
  &:nth-child(${props => props.$nthChild}) {
    @media screen and (min-height: 400px) {
      top: ${props => props.$headingIndex * 1.75}em;
      bottom: ${props => (props.$headingCount - props.$headingIndex - 1) * 1.75 + (props.$headingIndex !== 3 ? 1.5 : 0)}em;
    }
  }
`

const HymnalNameRow = styled.td`
  padding-top: 0.5em;
`

const HymnalNameGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const HymnalName = styled.div`
`

const HymnalNameSecondLine = styled.div`
  font-family: Jost-Regular, 'Arial', sans-serif;
  font-size: 0.7em;
  padding-bottom: 0.5em;
`

const HymnalMessage = styled.td`
  white-space: normal;
  text-align: center;
`

const HymnRow = styled.tr`
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: normal;
`

const EllipsisCell = styled.td`
  font-family: Jost-SemiBold, "Arial", sans-serif;
  letter-spacing: 3px;
`

const ScrollableHymnRow = styled(HymnRow)<{
  $headingIndex: number;
  $nthChild: number;
}>`
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: normal;

  &:nth-child(${props => props.$nthChild}) {
    // TODO(hewitt): This calculation is not quite right, but close enough for now.
    //               The idea is that the first hymn following a header should scroll up to just below the header.
    scroll-margin-top: ${props => (props.$headingIndex + 2) * 2.6 - 1.6}em;
  }
`
