import autoBind from 'auto-bind';
import {createAudioContext} from './util/instrument';
import {
  addListener,
  cacheGet,
  CacheKey,
  DefaultValues,
  removeListener,
  Voice
} from "./data/client_cache";
import {encodeMusicURI, generatePathFor} from "./util/path";
import {vocalsPath} from "./shared";
import {ensureExists} from "./common/util";

const defaultTempo = 100;

export class VocalsPlayer {
  audioContext = createAudioContext();
  initialTime: string | undefined = '0:00';
  interval: number | undefined;
  mostRecentTick: number | undefined;
  isFinishedLoading: boolean = false;
  playing?: boolean;
  experiencingUserInputOnTimeSlider: boolean = false;
  initialTempo = defaultTempo;
  urlPrefix: string;
  silentNode: OscillatorNode | undefined;
  songReady: (tempo: number) => void;
  onNetworkError: (response?: Response) => void;
  endOfFile: () => void;
  voiceValues = DefaultValues[CacheKey.VoiceValues];
  buffers: {[voice: string]: AudioBuffer} = {};
  // TODO(hewitt): Why are we not using standard AudioElements for vocals instead of web audio
  //               Switching to AudioElements would be easy & may provide background playback
  nodes: {[voice: string]: {source: AudioBufferSourceNode; gain: GainNode}} = {}
  _startedAt: number = 0;
  _currentPercent: number = 0; // use _startedAt if playing is true

  constructor(
    hymnalName: string,
    songName: string,
    songReady: (tempo: number) => void,
    onNetworkError: (response?: Response) => void,
    endOfFile: () => void,
  ) {
    autoBind(this);

    this.urlPrefix = generatePathFor(vocalsPath, ensureExists(hymnalName), songName) + '/' + encodeMusicURI(songName);

    this.songReady = songReady;
    this.onNetworkError = onNetworkError;
    this.endOfFile = endOfFile;

    // TODO(hewitt): Catch end of file event for Web Audio node
    // this.midiPlayer.on('endOfFile',  this._onEndOfFile.bind(this));

    void this._loadMP3Vocals();

    this.interval = window.setInterval(this.updateTimeSlider, 1000);

    this._refreshVoiceValues();
    addListener(CacheKey.VoiceValues, this._refreshVoiceValues);
  }

  private _getMP3URL(voice: Voice) {
    const suffixMap = {
      [Voice.Soprano]: ' Soprano.mp3',
      [Voice.Alto]: ' Alto.mp3',
      [Voice.Tenor]: ' Tenor.mp3',
      [Voice.Bass]: ' Bass.mp3',
      [Voice.Piano]: ' Piano.mp3',
    };
    return this.urlPrefix + encodeMusicURI(suffixMap[voice]);
  }

  private async _loadMP3Vocals() {
    const promises: Promise<void>[] = [];
    for (const voice of Object.values(Voice)) {
      promises.push(this._downloadMP3(voice));
    }
    await Promise.all(promises);

    this.isFinishedLoading = true;
    this.songReady(this.initialTempo);
  }

  private async _downloadMP3(voice: Voice): Promise<void> {
    const response = await fetch(this._getMP3URL(voice));
    if (!response.ok) {
      this.onNetworkError(response);
      return;
    }
    const buffer = await response.arrayBuffer();
    this.buffers[voice] = await this.audioContext.decodeAudioData(buffer);
  }

  private _createAudioNodes() {
    this.nodes = {};
    for (const voice of Object.values(Voice)) {
      const source = this.audioContext.createBufferSource();
      const gainNode = this.audioContext.createGain();
      source.buffer = this.buffers[voice];
      source.connect(gainNode);
      gainNode.connect(this.audioContext.destination);
      gainNode.gain.setValueAtTime(1.0, 0);
      this.nodes[voice] = {source, gain: gainNode};
    }
  }

  private _refreshVoiceValues() {
    this.voiceValues = cacheGet(CacheKey.VoiceValues);
  }

  private _onEndOfFile() {
    this.mostRecentTick = undefined;
    if (this.playing) {
      // this.audioElements.forEach(element => element.pause());
      setTimeout(() => {
        // user may have paused the player at the end of the song, in which case we don't want to restart it
        this.endOfFile()
      }, 100);
    }
  }

  restart() {
    // TODO(hewitt): Why do we not reset the audio elements to zero here?
    // this.audioElements.forEach(element => element.currentTime = 0);
    this.updateTimeSlider();
  }

  get duration(): number {
    const buffer = this.buffers[Voice.Soprano];
    return buffer?.duration ?? 0;
  }

  setLocationPercentage(percent: number) {
    // this.audioElements.forEach(element => element.currentTime = this.duration * (percent / 100));
  }

  private static _getTimeValue (secondsElapsed: number): string {
    const minutes = Number(Math.trunc(secondsElapsed / 60));
    const seconds = (Math.round(secondsElapsed % 60)).toString().padStart(2,'0');
    return minutes + ':' + seconds;
  }

  getTempoValue(): number {
    return 0;
  }

  setNewTime() {
    const timeSliderElement = document.getElementById('time-slider');
    const sliderValue = Number((timeSliderElement as HTMLInputElement).value) / 100;
    const isPlaying = this.playing;
    if (isPlaying) {
      this.pause();
    }
    this._currentPercent = sliderValue * 100;
    if (isPlaying) {
      this.play();
    }
    this.experiencingUserInputOnTimeSlider = false;
  }

  displayTime() {
    this.experiencingUserInputOnTimeSlider = true;
    const timeSliderElement = document.getElementById('time-slider');
    const timeOutputElement = document.getElementById('time-output');
    const secondsSelected = (Number((timeSliderElement as HTMLInputElement).value) / 100) * this.duration;
    (timeOutputElement as HTMLOutputElement).innerHTML = VocalsPlayer._getTimeValue(secondsSelected);
  }

  onTempoOrVolumeChange() {
    if (this.playing) {
      const voiceValues = cacheGet(CacheKey.VoiceValues);
      for (const voice of Object.values(Voice)) {
        this.nodes[voice].gain.gain.setValueAtTime(voiceValues[voice], 0);
      }
    }
  }

  private _getCurrentPercent(): number {
    if (this.playing) {
      return Math.min(100, (this.audioContext.currentTime - this._startedAt) / this.duration * 100);
    }
    return this._currentPercent;
  }

  private _getTimeOutput(): string {
    const currentTime = (this._getCurrentPercent() / 100) * this.duration;

    if (this.isFinishedLoading) {
      return VocalsPlayer._getTimeValue(currentTime);
    }
    else {
      return 'LOADING'
    }
  }

  play({endOfFile}: {endOfFile?: boolean} = {}) {
    const currentLocation = (this._getCurrentPercent() / 100) * this.duration;
    this.playing = true;
    if (!endOfFile) {
      this._startSilence();
    }
    this.mostRecentTick = undefined;

    // cannot call start on the same source node twice, so recreate all the nodes
    this._createAudioNodes();
    for (const {source} of Object.values(this.nodes)) {
      source.start(0, currentLocation);
    }
    this._startedAt = this.audioContext.currentTime - currentLocation;
  }

  pause({endOfFile, userAction}: {endOfFile?: boolean, userAction?: boolean} = {}) {
    // Would need to record the pause time for subsequent restart
    // See this website:  https://stackoverflow.com/a/18562855/998490
    for (const {source} of Object.values(this.nodes)) {
      source.stop();
    }
    this._currentPercent = this._getCurrentPercent();

    this._stopSilence();
    this.playing = false;
    this.updateTimeSlider();
  }

  updateTimeSlider() {
    if (
      this.isFinishedLoading &&
      !this.experiencingUserInputOnTimeSlider
    ) {
      const timeSliderElement = document.getElementById('time-slider');
      const timeOutputElement = document.getElementById('time-output');
      if (timeSliderElement) {
        (timeSliderElement as HTMLOutputElement).value = this._getCurrentPercent().toString();
      }
      if (timeOutputElement) {
        (timeOutputElement as HTMLOutputElement).innerHTML = this._getTimeOutput();
      }
    }
  }

  // On iOS, we must begin playing a note right away when the play button is pressed
  // Thus, we play a silent note for the duration of the song - until pause is clicked
  private _startSilence() {
    this._stopSilence();
    this.silentNode = this.audioContext.createOscillator();
    this.silentNode.type = 'sine';
    this.silentNode.frequency.setValueAtTime(200, this.audioContext.currentTime);
    const silentGain = this.audioContext.createGain();
    silentGain.gain.setValueAtTime(0, this.audioContext.currentTime);
    silentGain.connect(this.audioContext.destination);
    this.silentNode.connect(silentGain);
    this.silentNode.start();
  }

  private _stopSilence() {
    if (this.silentNode) {
      this.silentNode.stop();
      this.silentNode = undefined;
    }
  }


  destroy() {
    clearInterval(this.interval);
    removeListener(CacheKey.VoiceValues, this._refreshVoiceValues);

    // stop music when back button is pressed
    if (this.playing) {
      this.pause();
    }
  }
}