import {useEffect, useState} from "react";
import * as server_api from "../common/server_api";
import {cacheGet, CacheKey, cacheSet} from "./client_cache";
import {useCache} from './use_cache';

export function useUserAttributes(): {
  isInternalUser: boolean;
} {
  const [user] = useCache(CacheKey.User);
  const [isInternalUser, setIsInternalUser] = useState<boolean>(() => Boolean(cacheGet(CacheKey.IsInternalUser)));

  useEffect(() => {
    if (!user) {
      setIsInternalUser(false);
      return;
    }
    void (async () => {
      try {
        const isInternalUserLocal = await server_api.getIsInternalUser();
        setIsInternalUser(isInternalUserLocal);
        cacheSet(CacheKey.IsInternalUser, isInternalUserLocal);
      } catch {}
    })();
  }, [user]);

  return {isInternalUser};
}