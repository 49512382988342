import * as server_api from "./common/server_api"
import {CacheKey, cacheRemove, cacheSet, UserOrGuest} from "./data/client_cache";
import {Church, SongList, SongListType} from "./common/model";
import {dateStringFromDate} from "./common/date_string";
import {registerUserTokenCallback} from './common/server_api';

function assert(condition: boolean, message?: string) {
  if (!condition) {
    throw new Error(message ?? 'Assertion failure');
  }
}

function assertEqual(lhs: any, rhs: any) {
  assert(lhs === rhs, `Expected to be equal: ${lhs} !== ${rhs}`);
}

const testUser = {email: 'test@crescendosw.com', name: 'Test User'}
// TODO(hewitt): Remove this when we remove Google login
// const testCredentials = 'Google eyJhbGciOiJSUzI1NiIsImtpZCI6IjkxNDEzY2Y0ZmEwY2I5MmEzYzNmNWEwNTQ1MDkxMzJjNDc2NjA5MzciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIyNjE0MTgzMjUxMjQtZ2JiaHZ1aTQycmdrOGFrZDZ2dTAyOW9ldmEyMnYzYWIuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIyNjE0MTgzMjUxMjQtZ2JiaHZ1aTQycmdrOGFrZDZ2dTAyOW9ldmEyMnYzYWIuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMTQ2MzI0NzAzNzEzNjEwNjIyMzAiLCJoZCI6ImNyZXNjZW5kb3N3LmNvbSIsImVtYWlsIjoidGVzdEBjcmVzY2VuZG9zdy5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzA0NDk1NjU0LCJuYW1lIjoiVGVzdCBBY2NvdW50IiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0pHOUJDTTVTdFZqSVdMM2lZd0d5WnZUZXFjVlRQVG4xUTV6VHpOVVRWcj1zOTYtYyIsImdpdmVuX25hbWUiOiJUZXN0IiwiZmFtaWx5X25hbWUiOiJBY2NvdW50IiwibG9jYWxlIjoiZW4iLCJpYXQiOjE3MDQ0OTU5NTQsImV4cCI6MTcwNDQ5OTU1NCwianRpIjoiYTA2NzU2MDQ3ZGQxMzNjY2NmY2Q1YWUyZDAxMDQ1ZjhiZDc5NTYwZCJ9.Yk3Do33hPPj_ac2fDIj9f43FmQ8gWMysxwTBSL4b-lUQdzlCzSl6k4bqoZf8D3RC92sX0je_iL7434PmmTKusv_HLJpP6Awj7tTp6XI7WhDJOBAp-9EyU9GEhQjpzbApau7PvM78PHby9NKeJlcok2efopuB3zrcTpvyLu3HNWhinm0q65tF6GZ4RYzr_BXfHNe4Kyqlq8DdXlJ8K2Q5XnPA0smOY7OqVjwjbTYsNAO5qG4fKRBvJs6ddhy4M-t2t9JFUKMlCZSvW0I7q-BVgW_nEFOEiDH2WL48PX1sKhrkZlYkywLDNhfjEqzbZfq9cOHeIhHRc-bhbiqPKMCvyg';
const testChurch: Church = {
  id: 1000000,
  name: '__Test Name__',
  location: '__Test Location__',
  city: '__Test City__',
  state: '__Test State__',
  country: '__Test Country__',
};
const oldDate = dateStringFromDate(new Date(1900, 0, 1));
const testHymnalName = 'Cantus Christi (2020)';
let originalUser: UserOrGuest | undefined;

export async function runClientTests() {
  let succeeded = false;
  try {
    await setup();
    await testUserChurch();
    await testFavorites();
    await testSongLists();
    succeeded = true;
  } catch (e: any) {
    alert('Test Failed: ' + e.message + '\n' + e.stack);
  } finally {
    await teardown();
  }
  if (succeeded) {
    console.log('Tests passed');
    alert('Test succeeded');
  }
}

async function setup() {
  // TODO(hewitt): Resurrect these tests once we have a church admin dashboard to replace Coda
  // Note: some actions, such as creating & deleting the test church, must be done as sysadmin
  //       other actions, such as setting a user's church, must be done as the test user
  // if (!await server_api.getIsSysAdmin()) {
  //   throw new Error('User must be sys admin to run client tests')
  // }
  // await createTestChurch();
  // const {token} = await upsertTestUser();
  // await server_api.grantChurchAdmin(testChurch.id, testUser.email);
  // originalUser = cacheGet(CacheKey.User);
  // cacheSet(CacheKey.User, testUser);
  // cacheSet(CacheKey.UserToken, token);
  // cacheSet(CacheKey.Credentials, testCredentials);
  // await clearTestUserData();
}
//
// async function createTestChurch() {
//   await server_api.upsertChurch(testChurch);
//   await server_api.deleteChurch(testChurch);
//   assert((await server_api.getChurches()).filter(church => church.id === testChurch.id)[0].deleted);
//   await server_api.upsertChurch(testChurch);
//   assert(!(await server_api.getChurches()).filter(church => church.id === testChurch.id)[0].deleted);
// }
//
// async function upsertTestUser(): Promise<{token: string}> {
//   const {token} = await server_api.upsertUser(testCredentials);
//   let threwException = false;
//   try {
//     await server_api.upsertUser(InvalidTestCredentials);
//   } catch {
//     threwException = true;
//   }
//   assert(threwException, 'expected exception for bogus user credentials');
//   return {token};
// }

async function clearTestUserData() {
  // TODO(hewitt): switch over to household
  // await server_api.setHouseholdChurch(householdToken, testChurch.id);
  // for (const songList of await server_api.getSongLists({sinceDate: oldDate})) {
  //   await server_api.deleteSongList(songList.date);
  // }
  // await server_api.setHouseholdChurch(householdToken, testChurch.id);
}

async function teardown() {
  try {
    await clearTestUserData();
  } catch {}

  if (originalUser) {
    cacheRemove(CacheKey.Credentials);
    cacheRemove(CacheKey.UserToken);
    cacheSet(CacheKey.User, originalUser);
  }

  try {
    await server_api.revokeChurchAdmin(testChurch.id, testUser.email);
    await server_api.deleteChurch(testChurch, {purge: true});
  } catch {}
}

async function testUserChurch() {
  // TODO(hewitt): switch over to household
  // console.log('testChurch Started');
  // assert(!await server_api.getHouseholdChurch());
  // await server_api.setHouseholdChurchId(stringFromChurch(testChurch));
  // assert.deepEqual(await server_api.getHouseholdChurch(), stringFromChurch(testChurch));
  // let threwException = false;
  // try {
  //   await server_api.setHouseholdChurchId(stringFromChurch({name: '__Bogus Church__', location: '__Nowhere__'}));
  // } catch {
  //   threwException = true;
  // }
  // assert(threwException, 'expected exception setting bogus church');
  // await server_api.setHouseholdChurchId(undefined);
  // console.log('testChurch Succeeded');
}

// To make the client tests work, we would need to register a test user account with the server.
// However, there are reasons these client tests are suboptimal:
// - They run inside a bundle, so the stack trace line numbers are incorrect
// - Because they run in the browser, they have no access to node modules like (assert.equal)
//   - Note that if an attempt is made to use node assertions, the error "process is not defined" will occur
//     because the 'process' variable is defined within Node but not the browser
// - They cannot currently be executed within the GitHub test framework (would require Puppeteer)
// A better way in the short term would be to run a node process that makes http requests against a server.
// See server_api.test.ts for notes on that direction.
const testUserToken = '06a0894b-c7a5-477d-ba26-1ab84fbbb0ec';

async function testFavorites() {
  console.log('testFavorites Started');
  const previousCallback = registerUserTokenCallback(() => testUserToken);
  try {
    const favorite = {hymnalName: testHymnalName, songNumber: 447};
    await server_api.addFavorite(favorite);
    assertEqual(await server_api.getFavorites(), [favorite]);
    await server_api.removeFavorite(favorite);
    assertEqual(await server_api.getFavorites(), []);
    console.log('testFavorites Succeeded');
  } finally {
    registerUserTokenCallback(previousCallback);
  }
}

async function testSongLists() {
  console.log('testSongLists Started');
  // note that JavaScript months are zero based (go figure...)
  const firstDate = new Date(2024, 0, 1);
  const secondDate = new Date(2024, 0, 2);
  const songLists: SongList[] = [
    {
      date: dateStringFromDate(firstDate),
      type: SongListType.WorshipService,
      songs: [
        {hymnal: testHymnalName, number: 52},
        {hymnal: testHymnalName, number: 24},
      ]
    },
    {
      date: dateStringFromDate(secondDate),
      type: SongListType.WorshipService,
      songs: [
        {hymnal: testHymnalName, number: 18},
        {hymnal: testHymnalName, number: 322},
        {hymnal: testHymnalName, number: 411},
      ]
    },
  ];
  assertEqual((await server_api.getSongLists({sinceDate: oldDate})).length, 0);
  {
    let threwException = false;
    try {
      await server_api.upsertSongList(songLists[1]);
    } catch {
      threwException = true;
    }
    assert(threwException, 'expected exception upserting a song list without first choosing a church');
  }
  // TODO(hewitt): switch over to household
  // const earlierDate = new Date(2023, 11, 31);
  // const laterDate = new Date(2024, 0, 3);
  // await server_api.setHouseholdChurchId(stringFromChurch(testChurch));
  // assertEqual((await server_api.getSongLists({sinceDate: oldDate})).length, 0);
  // await server_api.upsertSongList(songLists[1]);
  // assert.deepEqual(await server_api.getSongLists({sinceDate: dateStringFromDate(laterDate)}), []);
  // assert.deepEqual(await server_api.getSongLists({sinceDate: dateStringFromDate(earlierDate)}), [songLists[1]]);
  // await server_api.upsertSongList(songLists[0]);
  // assert.deepEqual(await server_api.getSongLists({sinceDate: dateStringFromDate(laterDate)}), []);
  // assert.deepEqual(await server_api.getSongLists({sinceDate: dateStringFromDate(earlierDate)}), songLists);
  // assert.deepEqual(await server_api.getSongLists({sinceDate: dateStringFromDate(secondDate)}), [songLists[1]]);
  // await server_api.setHouseholdChurchId(undefined);
  console.log('testSongLists Succeeded');
}

// async function testHymnals() {
//   const hackedObject = {"hacked": true}
//
//   let allAsciiString = ''
//   for (let i = 0; i<257; i++){
//     allAsciiString += (String.fromCharCode(i));
//   }
//
//   const allHymnalsPath =  `${hymnalsPath}/manifest.json`
//   const eachHymnalPath = (hymnal: string) => `${hymnalsPath}/${hymnal}/manifest.json`
//   const hymnalsManifest = await fetch(allHymnalsPath)
//     .then((response) => response.json())
//     .then((data: string[]) => {
//       return data;
//     })
//   const hymnals = await Promise.all(hymnalsManifest.map(hymnalName => fetch(eachHymnalPath(hymnalName))))
//     .then((responses) => Promise.all(responses.map(response => response.json())))
//     .then((hymnLists: Hymn[][]) => {
//         return (hymnLists.map((hymn) => {return [hymn]}));
//     })
// }

// TODO(hewitt): test /api/issues (addIssue) with anonymous user to exercise code path in getUserToken()

/*
// *** TEST ADDING FAVORITES WORKS *** //
await fetch('/api/addFavorite', {
  method: 'POST',
  headers: {
    'Authorization': `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    "hymnal": "Cantus Christi 2020",
    "number": 1,
    "title": "Psalm 1",
    "psalm": "Psalm 1"
  }),
}).then(response => response.json())
  .then(data => {
    assertEqual(data, 1, `TEST ADDING FAVORITES WORKS: ${data}.`)
  })


// *** TEST ADDING DUPLICATE FAVORITE *** //
await fetch('/api/addFavorite', {
  method: 'POST',
  headers: {
    'Authorization': `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    "hymnal": "Cantus Christi 2020",
    "number": 1,
    "title": "Psalm 1",
    "psalm": "Psalm 1"
  }),
}).then(response => response.json())
  .then(data => {
    assertEqual(data, -1, `TEST ADDING DUPLICATE FAVORITES WORKS: ${data}.`)
  })


// *** TEST ADDING SECOND FAVORITE *** //
await fetch('/api/addFavorite', {
  method: 'POST',
  headers: {
    'Authorization': `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    "hymnal": "Cantus Christi 2002",
    "number": 12,
    "title": "I in the Lord Do Put My Trust",
    "psalm": "Psalm 11"
  }),
}).then(response => response.json())
  .then(data => {
    assertEqual(data, 2, `TEST ADDING SECOND FAVORITE: ${data}.`)
  })


// *** TEST DELETING FAVORITES WORKS *** //
await fetch('/api/deleteFavorite', {
  method: 'POST',
  headers: {
    'Authorization': `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    "hymnal": "Cantus Christi 2020",
    "number": 1,
    "title": "Psalm 1",
    "psalm": "Psalm 1"
  }),
}).then(response => response.json())
  .then(data => {
    assertEqual(data, 1, `TEST DELETING FAVORITES WORKS: ${data}.`)
  })


// *** TEST DELETING SAME FAVORITE *** //
await fetch('/api/deleteFavorite', {
  method: 'POST',
  headers: {
    'Authorization': `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    "hymnal": "Cantus Christi 2020",
    "number": 1,
    "title": "Psalm 1",
    "psalm": "Psalm 1"
  }),
}).then(response => response.json())
  .then(data => {
    assertEqual(data, 1, `TEST DELETING SAME FAVORITE: ${data}.`)
  })


// *** TEST DELETING SECOND FAVORITE *** //
await fetch('/api/deleteFavorite', {
  method: 'POST',
  headers: {
    'Authorization': `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    "hymnal": "Cantus Christi 2002",
    "number": 12,
    "title": "I in the Lord Do Put My Trust",
    "psalm": "Psalm 11"
  }),
}).then(response => response.json())
  .then(data => {
    assertEqual(data, 0, `TEST DELETING SECOND FAVORITE: ${data}.`)
  })


// *** TEST +- FAVORITES WITH EVERY HYMN IN THE CANTUS *** //
if (hymnals) {
  for (const hymns of hymnals) {
    for (const hymn of hymns[0]) {

      await fetch('/api/addFavorite', {
        method: 'POST',
        headers: {
          'Authorization': `${wellFormedJwt}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(hymn),
      }).then(response => response.json())
        .then(data => {
          assertEqual(data,
            1,
            `TEST ADDING WITH EVERY HYMN IN THE CANTUS: ${data}.`)
        })

      await fetch('/api/deleteFavorite', {
        method: 'POST',
        headers: {
          'Authorization': `${wellFormedJwt}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(hymn),
      }).then(response => response.json())
        .then(data => {
          assertEqual(data,
            0,
            `TEST DELETING WITH EVERY HYMN IN THE CANTUS: ${data}.`)
        })
    }}}


// TODO: Protect server by type checking incoming hymn requests
// // *** TEST ADDING FAVORITES WITH SOMETHING OTHER THAN A HYMN *** //
// await fetch('/api/addFavorite', {
//   method: 'POST',
//   headers: {
//     'Authorization' : `wellFormedJwt`,
//     'Content-Type': 'application/json'
//   },
//   body: JSON.stringify(hackedObject),
// }).then(response => response.json())
//   .then(data => {
//     const length = data
//     if (length + 1) {
//       console.log(length)
//     } else {
//       console.log("Something went wrong when adding the song.")
//     }
//   })


// *** TEST +- FAVORITES WITH MALICIOUS CODE SENT INSTEAD OF A HYMN *** //
await fetch('/api/addFavorite', {
  method: 'POST',
  headers: {
    'Authorization': `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({'hacked': '[]"}]\''}),
}).then(response => response.json())
  .then(data => {
    assertEqual(data,
      1,
      `TEST ADDING FAVORITES WITH MALICIOUS CODE SENT INSTEAD OF A HYMN: ${data}.`)
  })

await fetch('/api/deleteFavorite', {
  method: 'POST',
  headers: {
    'Authorization': `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({'hacked': '[]"}]\''}),
}).then(response => response.json())
  .then(data => {
    assertEqual(data,
      0,
      `TEST DELETING FAVORITES WITH MALICIOUS CODE SENT INSTEAD OF A HYMN: ${data}.`)
  })


// *** TEST +- FAVORITES WHERE HYMN HAS EVERY ASCII CHARACTER *** //
await fetch('/api/addFavorite', {
  method: 'POST',
  headers: {
    'Authorization': `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    "hymnal": "Cantus Christi 2020",
    "number": "1",
    "title": `${allAsciiString}`,
    "psalm": "Psalm 1"
  }),
}).then(response => response.json())
  .then(data => {
    assertEqual(data,
      1,
      `TEST ADDING FAVORITES WHERE HYMN HAS EVERY ASCII CHARACTER: ${data}.`)
  })

await fetch('/api/deleteFavorite', {
  method: 'POST',
  headers: {
    'Authorization': `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    "hymnal": "Cantus Christi 2020",
    "number": "1",
    "title": `${allAsciiString}`,
    "psalm": "Psalm 1"
  }),
}).then(response => response.json())
  .then(data => {
    assertEqual(data,
      0,
      `TEST DELETING FAVORITES WHERE HYMN HAS EVERY ASCII CHARACTER: ${data}.`)
  })


// *** TEST GETTING FAVORITES WORKS *** //
await fetch('/api/getFavorites', {
  method: 'POST',
  headers: {
    'Authorization' : `${wellFormedJwt}`,
    'Content-Type': 'application/json'
  },
}).then(response => response.json())
  .then(data => {
    const favoritesString = Object.values(data[0])
    const hymnData = typeof favoritesString[0] === "string" ? favoritesString[0] : "null"
    assertEqual(hymnData,
      "[]",
      `TEST GETTING FAVORITES WORKS: ${data}`)
  })

*/
