import * as server_api from '../../../src/common/server_api';
import {useCache} from '../../data/use_cache';
import {cacheGet, CacheKey} from '../../data/client_cache';
import styled from 'styled-components';
import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import '../../shared.css'
import '../settings_page.css'
import {HouseholdStatus} from '../../common/model';
import {
  AppleSubscriptionInfo,
  clearCachedHouseholdInfo,
  isInsideAppleAppStoreApp,
  isInsideGooglePlayApp,
  isInsideMobileApp,
  manageAppleSubscription,
  requestAppleSubscriptionInfo,
  useSetupIOSSubscriptionInfoCallback
} from '../../util/billing';
import {Header} from '../header';
import {ChooseChurchPage} from '../choose_church_page';
import {ReactComponent as ForwardArrow} from '../../assets/arrow-next-right-icon.svg';
import {SignUpButton} from '../../util/sign_up_button';
import {confirm} from '../../util/confirm'
import {SettingsTransitionWrapper} from '../../shared';
import {useChurch} from '../../data/use_church';

enum Display {Household, ChooseChurch}

export const HouseholdSettingsPage = ({visible}: {visible: boolean}) => {
  const [familyLastName] = useCache(CacheKey.FamilyLastName);
  const [householdEmail] = useCache(CacheKey.HouseholdEmail);
  const {church} = useChurch();
  const [householdStatus] = useCache(CacheKey.HouseholdStatus);
  const churchName = church?.name ?? "No Church";
  const [display, setDisplay] = useState(Display.Household);

  const onSetChurch = useCallback(() => {
    setDisplay(Display.Household);
  }, []);

  const wrapWithTransition = useCallback((title: string, component: ReactElement, page: Display) => (
    <SettingsTransitionWrapper $left={(display === page) ? "0" : "100%"}>
      <Header title={title} onBack={() => setDisplay(Display.Household)}/>
      {component}
    </SettingsTransitionWrapper>
  ), [display]);

  const renderChooseChurch = useCallback(() => {
    return wrapWithTransition(
      'Choose Church',
      <ChooseChurchPage onSetChurch={onSetChurch} location={'settings'}/>,
      Display.ChooseChurch
    );
  }, [onSetChurch, wrapWithTransition]);

  const onShowChooseChurch = useCallback(() => {
    setDisplay(Display.ChooseChurch)
  }, []);

  const onDeleteHousehold = useCallback(async () => {
    if (await confirm({confirmation: <span>Are you sure you wish to<br/>delete your household?</span>})) {
      clearCachedHouseholdInfo();
      const householdToken = cacheGet(CacheKey.BillingHouseholdToken);
      if (!householdToken) {
        return;
      }
      // clear all household data on server
      await server_api.clearHousehold({token: householdToken});
      window.location.href = '/settings';
    }
  }, []);

  const showDeleteButton = isInsideMobileApp() && householdEmail;

  const renderHousehold = useCallback(() => (
    <div className="settingsBox">
      <table className="settings">
        <tbody>
          <tr className="setting arrow">
            <td className="settingName">Last Name</td>
            <td className="currentValue">{familyLastName} </td>
            <td className='settingAction'>
              <ForwardArrow style={{visibility: 'hidden'}}/>
            </td>
          </tr>
          <tr className="setting arrow">
            <td className="settingName">Email</td>
            <td className="currentValue">{householdEmail} </td>
            <td className='settingAction'>
              <ForwardArrow style={{visibility: 'hidden'}}/>
            </td>
          </tr>
          <tr onClick={onShowChooseChurch} className="setting arrow">
            <td className="settingName">Church</td>
            <td className="currentValueEllipse">{churchName}</td>
            <td className='settingAction'>
              <ForwardArrow/>
            </td>
          </tr>
          <tr className="setting arrow hidden">
            <td className="settingName">Family Members</td>
            <td className="currentValueEllipse">{"Joe, Martha, Jim"}</td>
            <td className='settingAction'>
              <ForwardArrow/>
            </td>
          </tr>
          <tr onClick={onDeleteHousehold} className={'setting arrow' + (showDeleteButton ? '' : ' hidden')}>
            <td className="settingName">Delete</td>
            <td className="currentValue"></td>
            <td className='settingAction'>
              <ForwardArrow/>
            </td>
          </tr>
        </tbody>
      </table>
      {householdStatus !== HouseholdStatus.Subscribed && <SubscribeButton/>}
      <ManageSubscription/>
      <SignOutButton>Sign Out</SignOutButton>
    </div>
  ), [
    familyLastName,
    householdStatus,
    churchName,
    householdEmail,
    onDeleteHousehold,
    onShowChooseChurch,
    showDeleteButton,
  ]);

  if (!visible) {
    return null;
  }

  return (
    <div className="settingsContent">
      {renderHousehold()}
      {renderChooseChurch()}
    </div>
  )
}

const SignOutButton = styled.button`
  display: none;
  color: white;
  background-color: red;
  font-size: 1em;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
`;

const SubscribeButton = () => (
  <SubscribeButtonWrapper>
    <SignUpButton subscribeButton={true}/>
  </SubscribeButtonWrapper>
);

const ManageSubscription = () => {
  const [householdStatus] = useCache(CacheKey.HouseholdStatus);
  const [hasAppleSubscription, setHasAppleSubscription] = useState<boolean>(false);

  // determine if iOS thinks we have a subscription (noop on other platforms)
  useSetupIOSSubscriptionInfoCallback(
    (subscriptionInfo: AppleSubscriptionInfo) => setHasAppleSubscription(Boolean(subscriptionInfo.expirationDate))
  );
  useEffect(() => { requestAppleSubscriptionInfo() }, []);

  if (householdStatus !== HouseholdStatus.Subscribed) {
    return null;
  }

  // TODO(hewitt): how to bring up Google Play subscription management UI?
  if (isInsideAppleAppStoreApp() && hasAppleSubscription) {
    return <ManageSubscriptionButton onClick={manageAppleSubscription}>Manage Subscription</ManageSubscriptionButton>
  }
  if (isInsideGooglePlayApp()) {
    <ManageSubscriptionMessage>
      Manage your subscription in Google Play on your device.
    </ManageSubscriptionMessage>
  }
  return (
      <ManageSubscriptionMessage>
        To manage your subscription, reach out
        to <BusinessEmailLink href="mailto:business@crescendosw.com">business@crescendosw.com</BusinessEmailLink>
      </ManageSubscriptionMessage>
  );
}

const ManageSubscriptionButton = styled.button`
  color: white;
  background-color: black;
  font-size: 1em;
  padding: 5px 15px;
  border-radius: 30px;
  margin-top: 10px;
  cursor: pointer;
`

const ManageSubscriptionMessage = styled.div`
  max-width: 500px;
`

const BusinessEmailLink = styled.a`
  text-decoration: underline;
`

const SubscribeButtonWrapper = styled.div`
  margin-top: 20px;
`