import {sleep} from "./sleep";

export interface CrunkerConstructorOptions {
  /**
   * Sample rate for Crunker's internal audio context.
   *
   * @default 44100
   */
  sampleRate: number;
}

export type CrunkerInputTypes = string | File | Blob;

/**
 * An exported Crunker audio object.
 */
export interface ExportedCrunkerAudio {
  blob: Blob;
  url: string;
  element: HTMLAudioElement;
}

/**
 * Crunker is the simple way to merge, concatenate, play, export and download audio files using the Web Audio API.
 */
export default class AudioBufferLite {
  private readonly _sampleRate: number;
  private readonly _channelData: Float32Array[];
  private readonly _fadeDurationSeconds = 1/8;

  constructor(channelCount: number, sampleRate: number, durationSec: number) {
    this._sampleRate = sampleRate;
    this._channelData = new Array(channelCount);
    for (let channel = 0; channel < channelCount; channel++) {
      this._channelData[channel] = new Float32Array(sampleRate * (durationSec + this._fadeDurationSeconds))
    }
  }

  /**
   * Records the specified note at the specified start with the specified duration into the specified buffer.
   * Volume multiplier should be between zero and one
   */
  recordNote(
    note: AudioBuffer,
    startSec: number,
    durationSec: number,
    volumeMultiplier: number
  ): void {
    if (this._channelData.length !== note.numberOfChannels) {
      throw new Error(`Buffer and note must have the same number of channels.`);
    }
    const startOffset = Math.round(startSec * this._sampleRate);
    const fadeSampleCount = this._fadeDurationSeconds * this._sampleRate;
    const sampleCount = Math.round(Math.min(durationSec, note.duration) * this._sampleRate);
    for (let channelNumber = 0; channelNumber < note.numberOfChannels; channelNumber++) {
      const channelBuffer = this._channelData[channelNumber];
      const noteData = note.getChannelData(channelNumber);
      for (let i = 0; i < (sampleCount + fadeSampleCount) && (i + startOffset) < channelBuffer.length; i++) {
        let value: number;
        if (i < sampleCount) {
          value = noteData[i] * volumeMultiplier;
          if (isNaN(value)) {
            console.log('found NaN');
          }
        } else if (i < noteData.length) {
          const remainingFadeSamples = (sampleCount + fadeSampleCount) - i;
          const fadeMultiplier = fadeSampleCount === 0 ? 0 : remainingFadeSamples / fadeSampleCount;
          value = noteData[i] * volumeMultiplier * fadeMultiplier;
          if (isNaN(value)) {
            console.log('found NaN');
          }
        } else {
          value = 0;
        }
        channelBuffer[i + startOffset] += value;
      }
    }
  }

  getChannelDataValue(channel: number, offset: number): number {
    return this._channelData[channel][offset];
  }

  async normalize({normalizationTarget}: {normalizationTarget: number}) {
    let maxValue = 0;
    const sleepCount = 5;
    const sleepInterval = this._channelData[0].length / sleepCount;
    for (let channelNumber = 0; channelNumber < this._channelData.length; channelNumber++) {
      const channelBuffer = this._channelData[channelNumber];
      for (let i = 0; i < channelBuffer.length; i++) {
        maxValue = Math.max(maxValue, Math.abs(channelBuffer[i]));
        if (i % sleepInterval === 0) { // yield to UI events
          await sleep(0);
        }
      }
    }

    const multiplier = normalizationTarget / maxValue;
    for (let channelNumber = 0; channelNumber < this._channelData.length; channelNumber++) {
      const channelBuffer = this._channelData[channelNumber];
      for (let i = 0; i < channelBuffer.length; i++) {
        channelBuffer[i] = channelBuffer[i] * multiplier;
        if (i % sleepInterval === 0) { // yield to UI events
          await sleep(0);
        }
      }
    }
  }

  /**
   * Exports the specified AudioBuffer to a Blob, Object URI and HTMLAudioElement.
   */
  exportAsWavBlob(): Blob {
    const interleaved = this._interleave();
    const dataView = this._writeHeaders(interleaved, this._channelData.length, this._sampleRate);
    const type: string = 'audio/wav';
    return new Blob([dataView], { type });
  }

  /**
   * Converts an AudioBuffer to a Float32Array.
   *
   * @internal
   */
  private _interleave(): Float32Array {
    if (this._channelData.length === 1) {
      return this._channelData[0];
    }
    const length = this._channelData.length * this._channelData[0].length;
    const result = new Float32Array(length);

    let index = 0;
    let inputIndex = 0;

    // for 2 channels its like: [L[0], R[0], L[1], R[1], ... , L[n], R[n]]
    while (index < length) {
      for (let channelNumber = 0; channelNumber < this._channelData.length; channelNumber++) {
        result[index++] = this._channelData[channelNumber][inputIndex];
      }

      inputIndex++;
    }

    return result;
  }

  /**
   * Writes the WAV headers for the specified Float32Array.
   *
   * Returns a DataView containing the WAV headers and file content.
   *
   * @internal
   */
  private _writeHeaders(buffer: Float32Array, numOfChannels: number, sampleRate: number): DataView {
    const bitDepth = 16;
    const bytesPerSample = bitDepth / 8;
    const sampleSize = numOfChannels * bytesPerSample;

    const fileHeaderSize = 8;
    const chunkHeaderSize = 36;
    const chunkDataSize = buffer.length * bytesPerSample;
    const chunkTotalSize = chunkHeaderSize + chunkDataSize;

    const arrayBuffer = new ArrayBuffer(fileHeaderSize + chunkTotalSize);
    const view = new DataView(arrayBuffer);

    this._writeString(view, 0, 'RIFF');
    view.setUint32(4, chunkTotalSize, true);
    this._writeString(view, 8, 'WAVE');
    this._writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * sampleSize, true);
    view.setUint16(32, sampleSize, true);
    view.setUint16(34, bitDepth, true);
    this._writeString(view, 36, 'data');
    view.setUint32(40, chunkDataSize, true);

    return this._floatTo16BitPCM(view, buffer, fileHeaderSize + chunkHeaderSize);
  }

  /**
   * Writes a string to a DataView at the specified offset.
   *
   * @internal
   */
  private _writeString(dataview: DataView, offset: number, header: string): void {
    for (let i = 0; i < header.length; i++) {
      dataview.setUint8(offset + i, header.charCodeAt(i));
    }
  }

  /**
   * Converts a Float32Array to 16-bit PCM.
   *
   * @internal
   */
  private _floatTo16BitPCM(dataview: DataView, buffer: Float32Array, offset: number): DataView {
    for (let i = 0; i < buffer.length; i++, offset += 2) {
      const tmp = Math.max(-1, Math.min(1, buffer[i]));
      dataview.setInt16(offset, tmp < 0 ? tmp * 0x8000 : tmp * 0x7fff, true);
    }

    return dataview;
  }
}
