import { createRoot } from 'react-dom/client';
import './index.css';
import './util/instrument';
import {RootPage} from "./pages/root_page";
import {monkeyPatchPushState} from "./util/use_location";
import {reportCrash, clearRetryCountAfterSuccessfulLoad} from "./util/crash";
import {generateMidiNoteNames, loadSoundfont} from "./util/instrument";
import {ensureExists} from "./common/util";
import {registerUserTokenCallback} from "./common/server_api";
import {cacheGet, CacheKey, cacheSet, Guest} from "./data/client_cache";
import {
  updateIsInsideGooglePlayApp,
  isInsideAppleAppStoreApp,
  requestIOSAppVersion,
  synchronizeHouseholdStatus, migrateUserChurchToHouseholdChurch, isInsideGooglePlayApp
} from './util/billing';
import {synchronizeChurchWithServer} from './data/use_church';
import {synchronizeAppConfigWithServer} from './data/app_config';
import {forwardConsoleLogToIOS} from './util/ios_logging';
import {recordConsoleLog} from './util/logging';

// todo(hewitt): remove once the iOS app is no longer calling
declare global {
  function ringerOn(): void;
  function ringerOff(): void;
}
global.ringerOn = () => {};
global.ringerOff = () => {};

/*
IF YOU MODIFY THE APP VERSIONING SYSTEM IN ANY OF THE FOLLOWING WAYS:
- change the variable name
- change the version number to more than one decimal place ('x.y')
    - 'x.yz'
    - 'x.y.z'
    - 'xy.z'
YOU MUST MODIFY 'test-build.js' LINE 98 AND OR LINE 99 ACCORDINGLY
*/


const unregister = false;

if ('serviceWorker' in navigator && window.location.host !== 'localhost') {
  window.addEventListener('load', function() {
    if (unregister) {
      unregisterServiceWorker();
    } else {
      registerServiceWorker();
    }
  });
}

function registerServiceWorker() {
  navigator.serviceWorker.register('./service-worker.js')
    .then(function(registration) {
      // registration was successful
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
}

function unregisterServiceWorker() {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      void registration.unregister()
    } })
}

window.addEventListener('error', function (event: ErrorEvent) {
  if (event.error.hasBeenCaught !== undefined) {
    return false;
  }
  event.error.hasBeenCaught = true;
  const message = {
    message: event.message,
    url: event.filename,
    line: event.lineno,
    column: event.colno,
    error: JSON.stringify(event.error)
  }

  // @ts-ignore
  if (window.webkit) {
    // @ts-ignore
    window.webkit.messageHandlers.error.postMessage(message);
  } else {
    console.log("Error:", message);
  }
});

monkeyPatchPushState();
window.addEventListener("error", reportCrash);
clearRetryCountAfterSuccessfulLoad();
loadSoundfont();
generateMidiNoteNames();
registerUserTokenCallback(() => cacheGet(CacheKey.UserToken));

void migrateUserChurchToHouseholdChurch();

// ask the iOS app tell us what version it is running
if (isInsideAppleAppStoreApp()) {
  requestIOSAppVersion();
  forwardConsoleLogToIOS();
}
recordConsoleLog();

if (isInsideGooglePlayApp()) {
  console.log(`*** INSIDE GOOGLE PLAY APP ***`);
}

(async () => {
  try {
    await updateIsInsideGooglePlayApp();
    await synchronizeHouseholdStatus({onlyIfNotSubscribed: false});
    await synchronizeChurchWithServer();
  } catch { }
})();
void synchronizeAppConfigWithServer();
const user = cacheGet(CacheKey.User);
if (!user) {
  cacheSet(CacheKey.User, Guest);
}

const container = document.getElementById('root');
const root = createRoot(ensureExists(container));
root.render(
  <RootPage />
);
