import {useCallback, useRef} from 'react';
import {ensureExists} from "../common/util";

interface Props {
  className?: string;
  defaultValue?: number;
  value?: number;
  id: string;
  max?: number;
  min?: number;
  onChange?: (value: number) => void;
  onPointerUp?: (value: number) => void;
  step?: number;
}

export function Slider({className, defaultValue, value, id, max, min, onChange, onPointerUp, step}: Props) {
  const onInput = useCallback(() => {
    const slider = ensureExists(document.getElementById(id));
    onChange?.(Number((slider as HTMLInputElement).value));
  }, [id, onChange]);

  const _onPointerUp = useCallback(() => {
    const slider = ensureExists(document.getElementById(id));
    onPointerUp?.(Number((slider as HTMLInputElement).value));
  }, [id, onPointerUp])

  const inputRef = useRef<HTMLInputElement>(null);

  if (value !== undefined && inputRef?.current) {
    inputRef.current.value = value.toString();
  }

  return (
    <input
      className={className}
      type='range'
      id={id}
      defaultValue={defaultValue}
      min={min}
      max={max}
      step={step}
      onInput={onInput}
      onPointerUp={_onPointerUp}
      ref={inputRef}
    />
  );
}