import {User} from "../common/model";
import {CacheKey, cacheSet} from "../data/client_cache";
import {favorites} from "../data/favorites";
import {useCache} from "../data/use_cache";
import {useCallback} from "react";

export function useAuthenticationHelper() {
  const setCredentials = useCache(CacheKey.Credentials)[1];
  const [, setUser] = useCache(CacheKey.User);
  const setUserAndCredentials = useCallback((user: User, credentials: string, userToken: string) => {
    setUser(user);
    setCredentials(credentials);
    cacheSet(CacheKey.UserToken, userToken);
    void favorites.reconcileWithServer();
  }, [setCredentials, setUser]);
  return {setUserAndCredentials};
}