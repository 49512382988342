import './failure_page.css';

interface Props {
  message: JSX.Element;
}
export const FailurePage = (props: Props) => {
  const {message} = props;
  return (
    <div id="failureWrapper">
      <div id="failureContent">
        {message}
      </div>
    </div>
  );
};
