import {useCache} from '../data/use_cache';
import {CacheKey} from '../data/client_cache';
import {HouseholdBillingPage} from '../util/path';
import {useLocation} from '../util/use_location';
import {Login} from './settings/login';
import * as server_api from '../common/server_api';
import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

export const AdminInvite = () => {
  const {location} = useLocation();
  const [householdToken] = useCache(CacheKey.BillingHouseholdToken);
  const [userToken] = useCache(CacheKey.UserToken);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState('');
  const {search} = location;
  const urlParams = new URLSearchParams(window.location.search);
  const churchId = urlParams.get('church-id');
  const churchAdminToken = urlParams.get('church-admin-token');

  useEffect(() => {
    if (churchId && churchAdminToken && userToken && householdToken && !finished && !error) {
      (async () => {
        try {
          await server_api.grantChurchAdmin({churchId: Number(churchId), churchAdminToken, userToken});
          await server_api.setUserHousehold({householdToken});
          await server_api.setHouseholdChurch(householdToken, {id: Number(churchId)});
          const oneHundredYearsHence = new Date();
          oneHundredYearsHence.setFullYear(new Date().getFullYear() + 100);
          await server_api.setHouseholdSubscriptionExpirationTimestamp(householdToken, oneHundredYearsHence.getTime());
          setFinished(true);
        } catch (e: any) {
          setError(e.message);
        }
      })();
    }
  }, [churchId, churchAdminToken, userToken, householdToken, finished, error]);

  const onClose = useCallback(() => {
    window.location.href = '/';
  }, []);

  if (!householdToken) {
    window.location.href = HouseholdBillingPage + search;
    return null;
  }

  if (!userToken) {
    return <Login visible={true} prompt="Please login to register as a church administrator"/>;
  }

  if (!churchId || !churchAdminToken) {
    return (
      <div>
        <Message>
          An internal error has occurred. <a href="mailto:support@crescendosw.com?subject=Missing church tokens for admin invite" style={{color: "blue", textDecoration: "underline"}}>Click
          here</a> to reach out to support.
        </Message>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </div>
    );
  }

  // useful for debugging
  // return (
  //   <div>
  //     <div>Household Token: {householdToken}</div>
  //     <div>User Token: {userToken}</div>
  //     <div>Church ID: {churchId}</div>
  //     <div>Church Admin Token: {churchAdminToken}</div>
  //   </div>
  // );

  if (error) {
    return (
      <div>
        <Message>{error}</Message>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </div>
    );
  }

  if (!finished) {
    return <Message>Setting up church administrator account...</Message>
  }

  return (
    <div>
      <Message>Congratulations, you are now a church administrator!</Message>
      <CloseButton onClick={onClose}>Close</CloseButton>
    </div>
  );
}

const Message = styled.div`
  font-size: 2em;
  margin: 25% 5% 0;
`

const CloseButton = styled.button`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.5em;
  margin: 10px;
  cursor: pointer;
`
