import {ReactComponent as CloseCircleIcon} from "../assets/close-circle.svg";
import React, {useState} from 'react';
import {RequestSongPage} from './request_song_page';
import {ensureExists} from '../common/util';
import {Document, Page} from 'react-pdf';
import {Hymn} from '../sequencer';
import useWindowDimensions from '../util/useWindowDimensions';
import {encodeMusicURI, generateFileName} from '../util/path';
import {hymnalsPDFPath} from '../shared';
import {useUserAttributes} from '../data/use_user_attributes';

interface Props {
  hymn: Hymn;
  onClose: () => void;
}

export function PdfViewerPage({hymn, onClose}: Props) {
  const [numPages, setNumPages] = useState<number | undefined>(undefined);
  const windowDimensions = useWindowDimensions();
  const pdfFile = generateFileName(hymn) + '.pdf';
  const pdfPath = `${hymnalsPDFPath}/${encodeMusicURI(ensureExists(hymn.hymnal))}/${encodeMusicURI(pdfFile)}`;
  const {isInternalUser} = useUserAttributes();

  return (
    <div key='pdf' className='pdfDoc'>
      <div onClick={onClose}><CloseCircleIcon className='closeButton'/></div>
      <div>
        <div style={{...(!hymn.issue && {display: 'none'})}}>
          {
            hymn.issue && !isInternalUser
              ? <RequestSongPage issue={hymn.issue} hymnalName={ensureExists(hymn.hymnal)} songNumber={hymn.number}/>
              : null
          }
        </div>
        <div style={{...(hymn.issue && !isInternalUser && {display: 'none'})}}>
          <Document
            file={pdfPath}
            options={{workerSrc: "/pdf.worker.js"}}
            onLoadSuccess={({numPages}) => setNumPages(numPages)}
            onLoadError={console.error}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map(page => <Page pageNumber={page} key={page} width={windowDimensions.width}/>)}
          </Document>
        </div>
      </div>
    </div>
  );
}
