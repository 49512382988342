import {Favorite} from "../common/model";
import * as server_api from '../common/server_api';
import {Hymn} from "../sequencer";
import {HymnalWithHymns} from "../pages/hymns_list";
import {cacheGet, CacheKey, cacheSet} from "./client_cache";
import {ensureExists} from "../common/util";

class Favorites {

  add(hymn: Hymn) {
    const {server, added, removed} = _getLists();
    const favorite: Favorite = {hymnalName: ensureExists(hymn.hymnal), songNumber: hymn.number};

    if(_includes(removed, favorite)) {
      cacheSet(CacheKey.FavoritesRemoved, removed.filter(value => !_equal(value, favorite)));
    } else {
      if (_includes(added, favorite) || _includes(server, favorite)) {
        throw Error(`Already a favorite (cannot be added): ${favorite}`);
      }
      cacheSet(CacheKey.FavoritesAdded, [...added, favorite]);
    }
    void this.reconcileWithServer({listChanged: true});
  }

  remove(hymn: Hymn) {
    const {server, added, removed} = _getLists();
    const favorite: Favorite = {hymnalName: ensureExists(hymn.hymnal), songNumber: hymn.number};

    if (_includes(added, favorite)) {
      cacheSet(CacheKey.FavoritesAdded, added.filter(value => !_equal(value, favorite)));
    } else {
      if (_includes(removed, favorite) || !_includes(server, favorite)) {
        throw Error(`Not yet a favorite (cannot be removed): ${favorite}`);
      }
      cacheSet(CacheKey.FavoritesRemoved, [...removed, favorite]);
    }
    void this.reconcileWithServer({listChanged: true});
  }

  get(): Hymn[] {
    return this._currentFavorites()
      .map(favorite => this._lookup(favorite))
      .filter(hymn => hymn !== undefined) as Hymn[];
  }

  async reconcileWithServer({listChanged}: {listChanged?: boolean} = {}) {
    const before = JSON.stringify(this._currentFavorites());
    try {
      const {added, removed} = _getLists();

      for (const favorite of added) {
        await server_api.addFavorite(favorite);
      }
      for (const favorite of removed) {
        await server_api.removeFavorite(favorite);
      }
      cacheSet(CacheKey.Favorites, await server_api.getFavorites());
      cacheSet(CacheKey.FavoritesAdded, []);
      cacheSet(CacheKey.FavoritesRemoved, []);
    } finally {
      const after = JSON.stringify(this._currentFavorites());
      if (listChanged || before !== after) {
        for (const callback of this._callbacks) {
          callback();
        }
      }
    }
  }

  includes(hymn: Hymn): boolean {
    const favorite: Favorite = {hymnalName: ensureExists(hymn.hymnal), songNumber: hymn.number};
    return _includes(this._currentFavorites(), favorite);
  }

  registerHymnal(hymnal: HymnalWithHymns) {
    const songNumbers: {[songNumber: number]: Hymn} = {};
    for (const hymn of hymnal.hymns) {
      songNumbers[hymn.number] = hymn;
    }
    this._hymnals[hymnal.name] = songNumbers;
    for (const callback of this._callbacks) {
      callback();
    }
  }

  registerCallback(callback: () => void): number {
    return this._callbacks.push(callback) - 1;
  }

  unregisterCallback(token: number) {
    this._callbacks.splice(token, 1);
  }

  private _callbacks: Array<() => void> = [];

  private _currentFavorites(): Favorite[] {
    const {server, added, removed} = _getLists();

    return [
      ...server.filter(favorite => !_includes(removed, favorite)),
      ...added,
    ];
  }

  private _lookup(favorite: Favorite): Hymn | undefined {
    const hymnal = this._hymnals[favorite.hymnalName];
    return hymnal ? hymnal[favorite.songNumber] : undefined;
  }

  private _hymnals: {[hymnalName: string]: {[songNumber: number]: Hymn}} = {};
}

function _getLists() {
  return {
    server: cacheGet(CacheKey.Favorites),
    added: cacheGet(CacheKey.FavoritesAdded),
    removed: cacheGet(CacheKey.FavoritesRemoved),
  };
}

function _includes(list: Favorite[], favorite: Favorite) {
  for (const current of list) {
    if (_equal(current, favorite)) {
      return true;
    }
  }
  return false;
}

function _equal(lhs: Favorite, rhs: Favorite) {
  return lhs.hymnalName === rhs.hymnalName && lhs.songNumber === rhs.songNumber;
}

export const favorites = new Favorites();
