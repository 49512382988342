import autoBind from 'auto-bind';
import MidiPlayer from 'midi-player-js';
import {ActiveNote, createAudioContext, loadInstrument, playNote, stopNote} from './util/instrument';
import {
  addListener,
  cacheGet,
  CacheKey,
  DefaultValues,
  removeListener,
  Voice
} from "./data/client_cache";

const msBetweenTempoChanges = 500;

export class MidiFilePlayerWebAudio {
  audioContext = createAudioContext();
  initialTime: string | undefined = '0:00';
  songLength?: number;
  interval: number | undefined;
  activeNotes: { [voice: string]: ActiveNote } = {};
  midiPlayer: MidiPlayer.Player;
  mostRecentTick: number | undefined;
  mostRecentTime: number | undefined;
  silentNode: OscillatorNode | undefined;
  mostRecentTempoChangeTime: number;
  tempoTimer: NodeJS.Timeout | undefined;
  audio?: HTMLAudioElement;
  isMidiFileLoaded: boolean = false;
  isInstrumentLoaded?: boolean;
  playing?: boolean;
  experiencingUserInputOnTimeSlider: boolean = false;
  initialTempo: string | undefined = '';
  midiFilePath: string;
  songReady: (tempo: number) => void;
  onNetworkError: (response: Response) => void;
  endOfFile: () => void;
  voiceValues = DefaultValues[CacheKey.VoiceValues];
  modulationHalfSteps: () => number;
  midiNoteNames: string[] = [];

  constructor(
    midiFilePath: string,
    songReady: (tempo: number) => void,
    onNetworkError: (response: Response) => void,
    endOfFile: () => void,
    modulationHalfSteps: () => number) {
    autoBind(this);

    this.midiFilePath = midiFilePath;
    this.songReady = songReady;
    this.onNetworkError = onNetworkError;
    this.endOfFile = endOfFile;

    this.midiPlayer = new MidiPlayer.Player(this._handleEvent);
    this.midiPlayer.on('endOfFile',  this._onEndOfFile.bind(this));
    this.mostRecentTempoChangeTime = Date.now();
    this._loadMidiFile();

    this.interval = window.setInterval(this.updateTimeSlider, 1000);
    loadInstrument(() => {
      this.isInstrumentLoaded = true;
      if (this.isMidiFileLoaded) {
        this.songReady(this.midiPlayer.getTempo())
      }
    });

    this._refreshVoiceValues();
    addListener(CacheKey.VoiceValues, this._refreshVoiceValues);

    this.modulationHalfSteps = modulationHalfSteps;
    this._generateMidiNoteNames();
  }

  private _generateMidiNoteNames() {
    // note names look like 'A0', 'Bb0', 'B0', 'C1', 'Db1', etc.
    // they wrap when going from B to C
    // see https://www.inspiredacoustics.com/en/MIDI_note_numbers_and_center_frequencies
    this.midiNoteNames = [];
    const midiLetters = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];
    const startingNumberOffset = 21;
    const startingLetterIndex = 9;
    const pianoKeyCount = 88;
    for (let pianoKey = 0; pianoKey < pianoKeyCount; pianoKey += 1) {
      const noteNumber = startingNumberOffset + pianoKey;
      const midiOctave = Math.floor((pianoKey + startingLetterIndex) / midiLetters.length);
      const letter = midiLetters[(startingLetterIndex + pianoKey) % midiLetters.length];
      const noteName = letter + midiOctave.toString();
      this.midiNoteNames[noteNumber] = noteName;
    }
  }
  private _refreshVoiceValues() {
    this.voiceValues = cacheGet(CacheKey.VoiceValues);
  }

  private _onEndOfFile() {
    this.mostRecentTick = undefined;
    if (this.playing) {
      this.midiPlayer.pause();
      setTimeout(() => {
        // user may have paused the player at the end of the song, in which case we don't want to restart it
        this.endOfFile()
      }, 100);
    }
  }

  setLocationPercentage(value: number) {
    this.midiPlayer.skipToPercent(value);
  }

  restart() {
    this.midiPlayer.skipToPercent(0);
    this.updateTimeSlider();
  }

  private static _getTimeValue (secondsElapsed: number): string {
    const minutes = Number(Math.trunc(secondsElapsed / 60));
    const seconds = (Math.trunc(secondsElapsed % 60)).toString().padStart(2,'0');
    return minutes + ':' + seconds;
  }

  getTempoValue(): number {
    const initialTempo = Number(this.initialTempo);
    const tempoSliderElement = document.getElementById('tempo-slider');
    const currentTempo = Number((tempoSliderElement as HTMLInputElement).value);
    return currentTempo * initialTempo;
  }

  setNewTime() {
    this.experiencingUserInputOnTimeSlider = false;
    const timeSliderElement = document.getElementById('time-slider');

    if (this.midiPlayer.isPlaying()) {
      this.midiPlayer.skipToPercent(Number((timeSliderElement as HTMLInputElement).value));
      this.midiPlayer.play();
    }
    else {
      this.midiPlayer.skipToPercent(Number((timeSliderElement as HTMLInputElement).value));
    }
  }

  displayTime() {
    this.experiencingUserInputOnTimeSlider = true;
    const timeSliderElement = document.getElementById('time-slider');
    const timeOutputElement = document.getElementById('time-output');
    let secondsSelected: number = 0;
    if (this.songLength) {
      secondsSelected = ((Number((timeSliderElement as HTMLInputElement).value) / 100) * this.songLength);
    }

    (timeOutputElement as HTMLOutputElement).innerHTML = MidiFilePlayerWebAudio._getTimeValue(secondsSelected);
  }

  private _setNewTempo() {
    const newTempo = this.getTempoValue();
    if (this.midiPlayer.getTempo() !== newTempo) {
      this.midiPlayer.setTempo(newTempo);
    }
    this.mostRecentTempoChangeTime = Date.now();
  }

  onTempoOrVolumeChange() {
    this.displayTempo();
    const msSinceLastTempoChange = Date.now() - this.mostRecentTempoChangeTime;
    if (this.tempoTimer) {
      clearTimeout(this.tempoTimer);
    }
    this.tempoTimer = setTimeout(this._setNewTempo, Math.max(0, msBetweenTempoChanges - msSinceLastTempoChange));
  }

  displayTempo() {
    const tempoOutputElement = document.getElementById('tempo-output');
    (tempoOutputElement as HTMLInputElement).innerHTML = (Math.round(this.getTempoValue())).toString();
  }

  private get _finishedLoading() {
      return this.isInstrumentLoaded && this.isMidiFileLoaded
  }

  private _getCurrentPercent(): number {
    return Math.max(0, 100 - this.midiPlayer.getSongPercentRemaining());
  }

  private _getTimeOutput(): string {
    let currentTime: number = 0;
    if (this.songLength) {
      currentTime = (this._getCurrentPercent() / 100) * this.songLength;
    }

    if (this._finishedLoading) {
      return MidiFilePlayerWebAudio._getTimeValue(currentTime);
    }
    else {
      return 'LOADING'
    }
  }

  private _handleEvent (event: any) {
    let gain: number = 100;
    const divisor = 250;

    // The mostRecentTick & mostRecentTime allow us to schedule all notes with the same tick
    // to play at the same time, 1/4 second in the future.  That way, even if the foreground
    // thread gets busy, the notes are already scheduled to play at exactly the same time,
    // so there will be no delay between any two notes unless the foreground thread is so busy
    // that it cannot service the next note for over 1/4 second.
    //
    // However, we can do better - we can make the tempo consistent as well.
    // What we need to know is what the current tick means in terms of AudioContext.currentTime
    // That way, the tempo will remain consistent even if the foreground thread becomes busy
    // because are always scheduling the next note 1/4 second in the future for the exact right
    // moment, so as long as the code doesn't get more than 1/4 second behind in scheduling, the
    // tempo will be consistent.
    // The trick here is to consistently relate the current tick to the AudioContext.currentTime
    // in the face of a changing tempo & song location.
    // const songTime = (event.tick / this.midiPlayer.getTotalTicks()) * this.midiPlayer.getSongTime();

    let when: number;
    if (this.mostRecentTick === event.tick) {
      when = this.mostRecentTime!;
    } else {
      this.mostRecentTick = event.tick;
      when = this.mostRecentTime = this.audioContext.currentTime + 1/4;
    }

    if (event.name === 'Note on' || event.name === 'Note off') {
      let voice: Voice;
      const channelOrTrack = this._useTracksInsteadOfChannels() ? event.track - 1 : event.channel;
      switch (channelOrTrack) {
        default:
        case 1: voice = Voice.Soprano; break;
        case 2: voice = Voice.Alto; break;
        case 3: voice = Voice.Tenor; break;
        case 4: voice = Voice.Bass; break;
      }

      if (event.name === 'Note on' && this.playing) {
        gain = event.velocity * (this.voiceValues[voice] / divisor);

        if (this.activeNotes[voice]) {
          stopNote(this.activeNotes[voice], when);
          delete this.activeNotes[voice];
        }

        if (gain > 0) {
          const noteName = this.midiNoteNames[event.noteNumber + this.modulationHalfSteps()];
          this.activeNotes[voice] = playNote(this.audioContext, noteName, gain, when);
        }
      } else if (event.name === 'Note off') {
        if (this.activeNotes[voice]) {
          stopNote(this.activeNotes[voice], when);
          delete this.activeNotes[voice];
        }
      }
    }
  }

  // On iOS, we must begin playing a note right away when the play button is pressed
  // Thus, we play a silent note for the duration of the song - until pause is clicked
  private _startSilence() {
    this._stopSilence();
    this.silentNode = this.audioContext.createOscillator();
    this.silentNode.type = 'sine';
    this.silentNode.frequency.setValueAtTime(200, this.audioContext.currentTime);
    const silentGain = this.audioContext.createGain();
    silentGain.gain.setValueAtTime(0, this.audioContext.currentTime);
    silentGain.connect(this.audioContext.destination);
    this.silentNode.connect(silentGain);
    this.silentNode.start();
  }

  private _stopSilence() {
    if (this.silentNode) {
      this.silentNode.stop();
      this.silentNode = undefined;
    }
  }


  play({endOfFile}: {endOfFile?: boolean} = {}) {
    this.playing = true;
    if (!endOfFile) {
      this._startSilence();
    }

    this.mostRecentTick = undefined;
    this.midiPlayer.play();
  }

  async pause({endOfFile, userAction}: {endOfFile?: boolean, userAction?: boolean} = {}) {
    if (this.playing && userAction) {
      // destroy & recreate the audio context to silence all currently playing notes
      // however, we only do this in response to a user action, or we lose permission to play further notes
      await this.audioContext.close();
      this.audioContext = createAudioContext();
    }

    if (!endOfFile) {
      this.midiPlayer.pause();
    }

    this._stopSilence();
    this.playing = false;
  }

  private _useTracksInsteadOfChannels() {
    return Boolean(this.midiPlayer.tracks) && this.midiPlayer.tracks!.length > 1;
  }

  private _loadMidiFile() {

    const loadArrayBuffer = (buffer: ArrayBuffer) => {
      this.midiPlayer.loadArrayBuffer(buffer);
      this.initialTempo = this.midiPlayer.getTempo().toString();
      this.songLength = this.midiPlayer.getSongTime();
      this.initialTime = '0:00';
      this.isMidiFileLoaded = true;
      if (this.isInstrumentLoaded) {
        this.songReady(this.midiPlayer.getTempo())
      }
    }

    fetch(this.midiFilePath)
        .then(
          response => {
            if (!response.ok) {
              this.onNetworkError(response);
              return;
            }
            response.arrayBuffer().then(buffer => loadArrayBuffer(buffer));
          },
          reason => console.log(reason)
        );
  }

  updateTimeSlider() {
    if (
      this._finishedLoading &&
      !this.experiencingUserInputOnTimeSlider
    ) {
      const timeSliderElement = document.getElementById('time-slider');
      const timeOutputElement = document.getElementById('time-output');
      if (timeSliderElement) {
        (timeSliderElement as HTMLOutputElement).value = this._getCurrentPercent().toString();
      }
      if (timeOutputElement) {
        (timeOutputElement as HTMLOutputElement).innerHTML = this._getTimeOutput();
      }
    }
  }

  destroy() {
    clearInterval(this.interval);
    removeListener(CacheKey.VoiceValues, this._refreshVoiceValues);

    // stop music when back button is pressed
    if (this.playing) {
      void this.pause();
    }
  }
}