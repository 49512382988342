import {Hymn} from "../sequencer";
import {Hash, Pathname, Search} from "history";
import {ensureExists} from "../common/util";

export const AppStoreUrl = 'https://apps.apple.com/us/app/sing-your-part/id1483413949?itsct=apps_box_link&itscg=30200';
export const HouseholdBillingPage = '/billing/household.html';
export const SubscribeBillingPage = '/billing/choice.html';
export const DownloadPage = '/billing/download.html';

export function generateFileName(hymn: Hymn) {
  let titleAllCaps = ensureExists(hymn.title).toUpperCase();
  const match = hymn.number.toString().match(/^(?<wholeNumber>\d+)(\.(?<decimal>\d+))?/);
  let number: string;
  if (match && match.groups) {
    const {wholeNumber, decimal} = match.groups;
    number = wholeNumber.padStart(3, '0') + (decimal ? `.${decimal.padEnd(2, '0')}` : '');
  } else {
    console.log(`Error parsing hymn number ${hymn.number}`);
    number = hymn.number.toString();
  }

  if(titleAllCaps.match(/^PSALM \d+$/)) {
    return number + ' - ' + hymn.title;
  }
  else {
    if(hymn.psalm) {
      return number + ' - ' + hymn.title + ' - ' + hymn.psalm;
    }
    else {
      return number + ' - ' + hymn.title;
    }
  }
}

export function generatePathFor(base: string, hymnalName: string, fileName: string) {
  // Cantus Christi hymnals were renamed to include parenthesis (e.g. "Cantus Christi (2002)")
  // Some churches still have the old links (e.g. The King's Congregation)
  if (hymnalName.includes('Cantus Christi') && !hymnalName.includes('(')) {
    hymnalName = hymnalName.replace(/(\d+)/, '($1)')
  }
  return `${base}/${encodeMusicURI(hymnalName)}/${encodeMusicURI(fileName)}`;
}

export function encodeMusicURI(uri: string) {
  return encodeURIComponent(uri)
    .replace(/!/g, '%21');
}

export enum Pages {
  Demo = 'demo',
  Favorites = 'favorites',
  Help = 'help',
  Library = 'library',
  Settings = 'settings',
  ThisWeek = 'this-week',
  AdminInvite = 'admin-invite',
  ChurchHouseholdInvite = 'church-household-invite',
}

export interface Location {
  pathname: Pathname;
  search: Search;
  hash: Hash;
}

const defaultPageForDemo = Pages.ThisWeek;

export function getCurrentPage(location: Location | undefined)
  : Exclude<Pages, Pages.Demo>
{
  const page = getPageFromLocation(location);
  if (page === Pages.Demo) {
    return defaultPageForDemo;
  } else if (!page) {
    return Pages.ThisWeek
  }
  return page;
}

export function getPageFromLocation(location: Location | undefined): Pages | undefined {
  if (!location) {
    return;
  }
  const pagePart = location.pathname.match(/\/(?<page>[^/]+)/)?.groups?.page;
  return pagePart && Object.values(Pages).includes(pagePart as Pages) ? pagePart as Pages : undefined;
}

export function getUrlForPage(page: Pages) {
  return '/' + page;
}
