import React from "react";
import './song_info.css'
import './report_song_issue.css'
import GoogleLogin from './authentication/google_login';
import {AppleLogin, showGoogleButton} from './authentication/apple_login';


interface Props {
  location: string;
  message: string;
}

export const ModalButtons = (props: Props) => {
  return (
    <div style={{position: "relative"}}>
      <div className="modal-message">
        {props.message}
      </div>
      <div style={{
        position: "absolute",
        bottom: "20px",
        margin: "auto",
        display: "flex",
        flexBasis: "auto",
        width: "100%"
      }}>
        <div hidden={!showGoogleButton()}>
          <GoogleLogin location={props.location}/>
        </div>
        <AppleLogin location={props.location}/>
      </div>
    </div>
  );
}
