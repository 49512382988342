import {
  Church,
  Favorite,
  HouseholdStatus,
  Hymnal,
  SongList,
  SongView,
  Subscription,
  TransposedSong,
  User
} from "../common/model";

export const Guest = 'guest';
export type UserOrGuest = User | 'guest';
export const enableShowAllHymnals = false;

export enum CacheKey {
  AppVersion = 'app-version',
  HymnalsHash = 'hymnals-hash',
  BackgroundPlayback = 'background-playback',
  BillingHouseholdToken = 'billing/household-token',
  Church = 'church',
  ChurchHymnals = 'church-hymnal-ids',
  ClientSubscription = 'billing/client-subscription',
  Credentials = 'credentials',
  CurrentHymnalName = 'current-hymnal-name',
  FamilyLastName = 'billing/family-last-name',
  Favorites = 'favorites',
  FavoritesAdded = 'favorites-added',
  FavoritesRemoved = 'favorites-removed',
  // TODO(hewitt): Remove 9/2024 - Both 'billing/church-id' and 'billing/church-name' replaced by 'church'
  HouseholdChurchId = 'billing/church-id',
  HouseholdChurchName = 'billing/church-name',
  // TODO(hewitt): prefix with Billing*, to differentiate between billing & in-app, for migration
  HouseholdEmail = 'billing/household-email',
  HouseholdStatus = 'billing/household-status',
  IOSAppVersion = 'ios-app-version',
  IsInternalUser = 'is-internal-user',
  KeyModulation = 'key-modulation',
  OpenLetterHasBeenRead = 'open-letter-has-been-read',
  PdfAutoDisplay = 'pdf-auto-display',
  PushChurch = 'pushChurch',
  PushNotificationLastDisplayDate = 'push-notification-last-display-date',
  Repeat = 'repeat',
  SongViews = 'song-views',
  User = 'user',
  UserToken = 'user-token',
  VerseCount = 'verse-count',
  VoiceValues = 'voice-values',
  ShowAllHymnals = 'show-all-hymnals',
  SongIntro = 'song-intro',
  ChooseChurchSkipped = 'choose-church-skipped',
  WeeklySongList = 'weekly-song-list',
}

interface TypeMap {
  [CacheKey.AppVersion]: string | undefined;
  [CacheKey.BackgroundPlayback]: boolean;
  [CacheKey.BillingHouseholdToken]: string | undefined;
  [CacheKey.HymnalsHash]: string | undefined;
  [CacheKey.Church]: Church | undefined;
  [CacheKey.ChurchHymnals]: Hymnal[] | undefined;
  [CacheKey.ClientSubscription]: (Subscription & {appAccountToken?: string}) | undefined;
  [CacheKey.Credentials]: string | undefined;
  [CacheKey.CurrentHymnalName]: string | undefined;
  [CacheKey.FamilyLastName]: string | undefined;
  [CacheKey.Favorites]: Favorite[];
  [CacheKey.FavoritesAdded]: Favorite[];
  [CacheKey.FavoritesRemoved]: Favorite[];
  [CacheKey.HouseholdChurchId]: number | undefined;
  [CacheKey.HouseholdChurchName]: string | undefined;
  [CacheKey.HouseholdEmail]: string | undefined;
  [CacheKey.HouseholdStatus]: HouseholdStatus;
  [CacheKey.IOSAppVersion]: number | undefined;
  [CacheKey.IsInternalUser]: boolean | undefined;
  [CacheKey.KeyModulation]: TransposedSong[];
  [CacheKey.OpenLetterHasBeenRead]: boolean | undefined;
  [CacheKey.PdfAutoDisplay]: boolean;
  [CacheKey.PushChurch]: true | undefined;
  [CacheKey.PushNotificationLastDisplayDate]: string | undefined; // Date does not work -> only object & scalar
  [CacheKey.Repeat]: boolean;
  [CacheKey.ShowAllHymnals]: boolean;
  [CacheKey.SongViews]: SongView[];
  [CacheKey.User]: UserOrGuest;
  [CacheKey.UserToken]: string | undefined;
  [CacheKey.VerseCount]: number | undefined;
  [CacheKey.VoiceValues]: VoiceValues;
  [CacheKey.SongIntro]: boolean | undefined;
  [CacheKey.ChooseChurchSkipped]: boolean | undefined;
  [CacheKey.WeeklySongList]: SongList[] | undefined;
}

export enum Voice {
  Soprano = 'soprano',
  Alto = 'alto',
  Tenor = 'tenor',
  Bass = 'bass',
  Piano = 'piano',
}

export const defaultVerseCount = 4; // allows defaulting to the hymn verse count

export const DefaultValues: {[key in CacheKey]: CachedType<key>} = {
  [CacheKey.AppVersion]: undefined,
  [CacheKey.BackgroundPlayback]: true,
  [CacheKey.BillingHouseholdToken]: undefined,
  [CacheKey.HymnalsHash]: undefined,
  [CacheKey.Church]: undefined,
  [CacheKey.ChurchHymnals]: undefined,
  [CacheKey.ClientSubscription]: undefined,
  [CacheKey.Credentials]: undefined,
  [CacheKey.CurrentHymnalName]: undefined,
  [CacheKey.FamilyLastName]: undefined,
  [CacheKey.Favorites]: [],
  [CacheKey.FavoritesAdded]: [],
  [CacheKey.FavoritesRemoved]: [],
  [CacheKey.HouseholdChurchId]: undefined,
  [CacheKey.HouseholdChurchName]: undefined,
  [CacheKey.HouseholdEmail]: undefined,
  [CacheKey.HouseholdStatus]: HouseholdStatus.Unknown,
  [CacheKey.IOSAppVersion]: undefined,
  [CacheKey.IsInternalUser]: undefined,
  [CacheKey.KeyModulation]: [],
  [CacheKey.OpenLetterHasBeenRead]: undefined,
  [CacheKey.PdfAutoDisplay]: false,
  [CacheKey.PushChurch]: undefined,
  [CacheKey.PushNotificationLastDisplayDate]: undefined, // Date does not work -> only object & scalar
  [CacheKey.Repeat]: false,
  [CacheKey.ShowAllHymnals]: !enableShowAllHymnals,
  [CacheKey.SongViews]: [],
  [CacheKey.User]: Guest,
  [CacheKey.UserToken]: undefined,
  [CacheKey.VerseCount]: undefined,
  [CacheKey.VoiceValues]: {
    [Voice.Soprano]: 1,
    [Voice.Alto]: 0.5,
    [Voice.Tenor]: 0.5,
    [Voice.Bass]: 0.5,
    [Voice.Piano]: 0.5,
  },
  [CacheKey.SongIntro]: undefined,
  [CacheKey.ChooseChurchSkipped]: undefined,
  [CacheKey.WeeklySongList]: undefined,
};

const nonPrefixedKeys = [
  CacheKey.AppVersion,
  CacheKey.BillingHouseholdToken,
  CacheKey.Church,
  CacheKey.ClientSubscription,
  CacheKey.FamilyLastName,
  CacheKey.HouseholdChurchId,
  CacheKey.HouseholdChurchName,
  CacheKey.HouseholdEmail,
  CacheKey.HouseholdStatus,
  CacheKey.HymnalsHash,
  CacheKey.IOSAppVersion,
  CacheKey.PushChurch,
  CacheKey.User,
  CacheKey.WeeklySongList,
];

export type CachedType<T extends CacheKey> = TypeMap[T];

export interface VoiceValues {
  [Voice.Soprano]: number;
  [Voice.Alto]: number;
  [Voice.Tenor]: number;
  [Voice.Bass]: number;
  [Voice.Piano]: number;
}

function parseJson(rawValue: string) {
  // Entries created by raw HTML pages were not originally JSON stringified... :(
  // numbers & booleans are not wrapped in quotes even when JSON stringified
  if (
      !rawValue.startsWith('{') &&
      !rawValue.startsWith('[') &&
      !rawValue.startsWith('"') &&
      !rawValue.match(/^\d/) &&
      rawValue !== 'true' &&
      rawValue !== 'false' &&
      rawValue !== 'undefined'
  ) {
    return rawValue;
  }
  return JSON.parse(rawValue);
}

export function cacheGet<T extends CacheKey>(key: T): CachedType<T> {
  const rawValue = _cacheGetRaw(_getPrefixedCacheName(key));
  if (rawValue !== undefined) {
    return parseJson(rawValue);
  }
  return DefaultValues[key];
}

export function cacheSet<T extends CacheKey>(key: T, value: CachedType<T>) {
  if (value === undefined) {
    cacheRemove(key);
    return;
  }
  const oldValue = _cacheGetRaw(_getPrefixedCacheName(key)) as CachedType<T>;
  _cacheSetRaw(_getPrefixedCacheName(key), JSON.stringify(value));
  if (value !== oldValue) {
    _callListeners(key, oldValue, value);
  }
}

export function cacheRemove<T extends CacheKey>(key: T) {
  const oldValue = _cacheGetRaw(_getPrefixedCacheName(key)) as CachedType<T>;
  _cacheRemoveRaw(_getPrefixedCacheName(key));
  _callListeners(key, oldValue);
}

function _cacheRemoveRaw(name: string) {
  localStorage.removeItem(name);
}

export function addListener<T extends CacheKey>(key: T, listener: Listener) {
  _listeners[key].push(listener);
}

export function removeListener<T extends CacheKey>(key: T, listener: Listener) {
  const index = _listeners[key].indexOf(listener);
  if (index !== -1) {
    _listeners[key].splice(index, 1);
  }
}

export function clearClientCache() {
  localStorage.clear();
}

function _cacheGetRaw(name: string): string | undefined {
  return localStorage.getItem(name) ?? undefined;
}

function _cacheSetRaw(name: string, value: string) {
  localStorage.setItem(name, value);
}

function _getPrefixedCacheName(key: CacheKey): string {
  if (nonPrefixedKeys.includes(key)) {
    return key;
  }
  const user = cacheGet(CacheKey.User);
  const prefix = user === Guest ? user : user.email;
  return `${prefix}/${key}`;
}

function _callListeners<T extends CacheKey>(key: T, _oldValue?: CachedType<T>, _newValue?: CachedType<T>) {
  for (const listener of _listeners[key]) {
    listener();
  }
}

// todo(hewitt): would be nice someday, but gathering up all old values when `user` changes is challenging
// export type ListenerArgs<T extends CacheKey> = {
//   oldValue: CachedType<T> | undefined;
//   newValue: CachedType<T> | undefined;
// };
// export type Listener<T extends CacheKey> = (args: ListenerArgs<T>) => void;

export type Listener = () => void;

const _listeners: {[key in CacheKey]: Listener[]} = {
  [CacheKey.AppVersion]: [],
  [CacheKey.BackgroundPlayback]: [],
  [CacheKey.BillingHouseholdToken]: [],
  [CacheKey.HymnalsHash]: [],
  [CacheKey.Church]: [],
  [CacheKey.ChurchHymnals]: [],
  [CacheKey.ClientSubscription]: [],
  [CacheKey.Credentials]: [],
  [CacheKey.CurrentHymnalName]: [],
  [CacheKey.FamilyLastName]: [],
  [CacheKey.Favorites]: [],
  [CacheKey.FavoritesAdded]: [],
  [CacheKey.FavoritesRemoved]: [],
  [CacheKey.HouseholdChurchId]: [],
  [CacheKey.HouseholdChurchName]: [],
  [CacheKey.HouseholdEmail]: [],
  [CacheKey.HouseholdStatus]: [],
  [CacheKey.IsInternalUser]: [],
  [CacheKey.IOSAppVersion]: [],
  [CacheKey.KeyModulation]: [],
  [CacheKey.OpenLetterHasBeenRead]: [],
  [CacheKey.PdfAutoDisplay]: [],
  [CacheKey.PushChurch]: [],
  [CacheKey.PushNotificationLastDisplayDate]: [],
  [CacheKey.Repeat]: [],
  [CacheKey.ShowAllHymnals]: [],
  [CacheKey.SongViews]: [],
  [CacheKey.User]: [],
  [CacheKey.UserToken]: [],
  [CacheKey.VerseCount]: [],
  [CacheKey.VoiceValues]: [],
  [CacheKey.SongIntro]: [],
  [CacheKey.ChooseChurchSkipped]: [],
  [CacheKey.WeeklySongList]: [],
};
