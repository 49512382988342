import {Hymn} from "./sequencer";
import {HymnIssue} from "./common/model";
import {isInsideAppleAppStoreApp} from './util/billing';
import styled from 'styled-components';

export const hymnalsPath = '/hymnals';
export const hymnalsPDFPath = '/hymnals';
export const vocalsPath = '/vocals';

export function getHymnFromUrl(search: string): Hymn[] | undefined{
  const url = new URLSearchParams(search);
  const hymnTitle = url.get('title');
  const hymnNumber = Number(url.get('number'));
  const hymnalName = url.get('hymnal');
  const issue = url.get('issue') as (HymnIssue | undefined);
  let hymn: Hymn;

  if(hymnTitle && hymnNumber && hymnalName) {
    const correctedHymnTitle = hymnTitle.replace(/_/g, ' ');
    const correctedHymnalName = hymnalName.replace(/_/g, ' ');
    const psalmName = url.get('psalm');

    if (psalmName) {
      const correctedPsalmName = psalmName.replace(/_/g, ' ');

      hymn = {
        hymnal: correctedHymnalName,
        title: correctedHymnTitle,
        number: hymnNumber,
        psalm: correctedPsalmName,
        issue,
      };
    } else {
      hymn = {
        hymnal: correctedHymnalName,
        title: correctedHymnTitle,
        number: hymnNumber,
        issue,
      };
    }
  } else {
    return undefined
  }
  return [hymn];
}

export const OuterPageContent = styled.div`
  display: flex;
  flex-flow: column;
  // Account for mobile nav bar height (iOS Safari fails to access the CSS variable, so short circuit)
  height: ${isInsideAppleAppStoreApp() ? '100vh' : 'var(--view-height, 100vh)'};
`

export const InnerPageContent = styled.div`
  flex: auto;
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
`

export const SettingsTransitionWrapper = styled(OuterPageContent)<{$left?: string;}>`
  position: fixed;
  left: ${props => props.$left};
  width: 100%;
  top: 0;
  bottom: 0;
  transition: left 200ms;
  transition-timing-function: ease-out;
`
export const BackgroundGradient = styled.div`
  background-image: linear-gradient(225deg, #5200BB, #000000);
  color: white;
  height: 100%;
`
