import React from 'react';
import "./kebab.css"
import {ReactComponent as InfoIcon} from "./assets/info-outline.svg";
import {ReactComponent as OptionsIcon} from "./assets/options.svg";
import {ReactComponent as PdfIcon} from "./assets/document-text-outline.svg";
import {ReactComponent as ReportIssueIcon} from "./assets/tools.svg";


interface Props {
  displaySongOptions: () => void;
  displaySongInfo: () => void;
  displayPdf: () => void;
  displayModal: () => void;
  close: () => void;
}

export const Kebab = (props: Props) => {

  const reportIssue = document.getElementById("reportIssueSetting")
  reportIssue?.addEventListener('click', function () {
    props.close()
    props.displayModal()
  })

  function renderKebab() {
    return (
      <div className="subMenus">
        <div className="navOption" onClick={() => props.displaySongInfo()}>
          <InfoIcon className="icon"/>
          <div className="optionText">
            Song Information
          </div>
        </div>

        <div onClick={() => props.displayPdf()} className="navOption">
          <PdfIcon className="icon"/>
          <div className="optionText">
            Open Sheet Music
          </div>
        </div>

        <div onClick={() => props.displaySongOptions()} className="navOption">
          <OptionsIcon className="icon"/>
          <div className="optionText">
            Playback Settings
          </div>
        </div>


        <div className="navOption" id="reportIssueSetting">
          <div className='icon'>
            <ReportIssueIcon style={{transform: "scale(.75)"}}/>
          </div>
          <div className="optionText">
            Report Issue
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderKebab()}
    </div>
  )
};
