import * as server_api from '../common/server_api';
import {cacheGet, CacheKey} from './client_cache';
import {AppConfig} from '../common/model';

const CachePrefix = 'app-config';
const CachePrefixOverride = 'app-config-override';
const ConfigSyncIntervalMinutes = 5;

// client only - in server code, use database.isAppConfigEnabled
export function isAppConfigEnabled(key: AppConfig): boolean {
  const overrideValue = localStorage.getItem(`${CachePrefixOverride}/${key}`);
  if (overrideValue) {
    return true;
  }
  const rawValue = localStorage.getItem(`${CachePrefix}/${key}`);
  return Boolean(rawValue ? JSON.parse(rawValue) : false);
}

export async function synchronizeAppConfigWithServer() {
  // ignore network errors (e.g. when offline)
  try {
    for (const key of Object.values(AppConfig)) {
      const rawValue = await server_api.getAppConfigValue(key);
      const value = processAppConfigValue(rawValue);
      if (value) {
        localStorage.setItem(`${CachePrefix}/${key}`, JSON.stringify(value));
      } else {
        localStorage.removeItem(`${CachePrefix}/${key}`);
      }
    }
  } catch { }
  setTimeout(synchronizeAppConfigWithServer, ConfigSyncIntervalMinutes * 60 * 1000);
}

// converts a vanilla app config object into a boolean
function processAppConfigValue(value?: any): boolean {
  if (value === undefined || value === null) {
    return false;
  }
  if (typeof value === 'boolean') {
    return value;
  }
  if ('internal' in value) {
    return Boolean(cacheGet(CacheKey.IsInternalUser));
  }
  // checking the environment is nonsense because we would need to manually set the value in each environment
  // if ('environment' in value) {
  //   const {environment}: {environment: Environment} = value;
  //   if (environment === Environment.Production && getEnvironment() === Environment.Production) {
  //     return true;
  //   }
  //   if (environment === Environment.Test && getEnvironment() !== Environment.Production) {
  //     return true;
  //   }
  //   return true;
  // }
  return false;
}
