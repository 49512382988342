import {useEffect} from 'react';
import * as server_api from '../common/server_api';
import {cacheGet, CacheKey, cacheRemove, cacheSet, Guest} from '../data/client_cache';
import {HouseholdStatus, PaymentProcessor} from '../common/model';
import {synchronizeChurchWithServer} from '../data/use_church';

const AppleSubscriptionInfoTimeoutMS = 10000;

export enum ProductId {
  HouseholdAnnualSubscription = 'com.crescendosw.ensemble.household.annual',
  HouseholdMonthlySubscription = 'com.crescendosw.ensemble.household.monthly',
}

export function isInsideAppleAppStoreApp(): boolean {
  // @ts-ignore
  return Boolean(window.webkit);
}

let isInsideGooglePlayAppCached = false;

export async function updateIsInsideGooglePlayApp() {
  // @ts-ignore
  if (!window.getDigitalGoodsService) {
    isInsideGooglePlayAppCached = false;
    return;
  }
  // @ts-ignore
  const digitalGoodsService = await window.getDigitalGoodsService("https://play.google.com/billing");
  isInsideGooglePlayAppCached = Boolean(digitalGoodsService);
}

export function isInsideGooglePlayApp(): boolean {
  // @ts-ignore
  return isInsideGooglePlayAppCached;
}

export function isInsideMobileApp(): boolean {
  return isInsideGooglePlayApp() || isInsideGooglePlayApp();
}

export function doesAppVersionSupportSubscriptions(): boolean {
  // @ts-ignore
  return Boolean(window.webkit?.messageHandlers?.subscribe);
}

export function appUpdateNeeded(): boolean {
  const iOSAppVersion = cacheGet(CacheKey.IOSAppVersion);
  return isInsideAppleAppStoreApp() && (!iOSAppVersion || iOSAppVersion < 3.5);
}

export function getMonthlySubscriptionPrice() {
  return isInsideMobileApp() ? 4.99 : 3.99
}

export interface AppleSubscriptionInfo {
  appAccountToken?: string;
  productId?: string;
  expirationDate?: string;
}

// sets up a callback on globalThis that is used by the iOS app to forward subscription info
export function useSetupIOSSubscriptionInfoCallback(callback: (subscriptionInfo: AppleSubscriptionInfo) => void) {
  useEffect(() => {
    // @ts-ignore
    globalThis.receiveSubscriptionInfo = (subscriptionInfo: AppleSubscriptionInfo) => {
      callback(subscriptionInfo);
    }
    // @ts-ignore
    return () => { delete globalThis.receiveSubscriptionInfo; };
  }, [callback]);
}

// sets up a callback on globalThis that is used by the iOS app to forward subscription info
export function useSetupIOSCanMakePurchasesCallback(callback: (canMakePurchases: boolean) => void) {
  useEffect(() => {
    // @ts-ignore
    globalThis.receiveCanMakePurchases = (canMakePurchases: boolean) => {
      callback(canMakePurchases);
    }
    // @ts-ignore
    return () => { delete globalThis.receiveCanMakePurchases; };
  }, [callback]);
}

export function requestAppleCanMakePurchases(): void {
  // @ts-ignore
  window.webkit?.messageHandlers?.requestCanMakePurchases?.postMessage({});
}

// a callback to the iOS app that requests subscription info, which is passed back via receiveSubscriptionInfo above
export function requestAppleSubscriptionInfo(): void {
  // @ts-ignore
  window.webkit?.messageHandlers?.requestSubscriptionInfo?.postMessage({});
}

export function subscribeApple(productId: ProductId, householdToken: string) {
  // @ts-ignore
  window.webkit?.messageHandlers?.subscribe?.postMessage({productId, householdToken});
}

export function manageAppleSubscription() {
  // @ts-ignore
  window.webkit?.messageHandlers?.manageSubscription?.postMessage({});
}

export async function synchronizeHouseholdStatus({onlyIfNotSubscribed}: {onlyIfNotSubscribed?: boolean} = {}) {
  const householdStatus = cacheGet(CacheKey.HouseholdStatus);
  const householdToken = cacheGet(CacheKey.BillingHouseholdToken);

  if (onlyIfNotSubscribed && householdStatus === HouseholdStatus.Subscribed) {
    return; // optimization to avoid banging server with every header render
  }

  try {
    if (isInsideMobileApp()) {
      await synchronizeClientSubscription();
    }

    if (isInsideAppleAppStoreApp()) {
      const clientSubscription = cacheGet(CacheKey.ClientSubscription);
      if (
        clientSubscription?.subscriptionExpirationTimestamp &&
        clientSubscription.subscriptionExpirationTimestamp > Date.now()
      ) {
        if (householdStatus !== HouseholdStatus.Subscribed) {
          cacheSet(CacheKey.HouseholdStatus, HouseholdStatus.Subscribed);
        }
        return;
      }
    } else if (isInsideGooglePlayApp() && householdToken) {
      // @ts-ignore
      const service = await window.getDigitalGoodsService("https://play.google.com/billing");
      const existingPurchases = await service.listPurchases();
      if (existingPurchases.length > 0) {
        // Note: Purchases signature: {itemId: 'annualfamily' | 'montlyfamily', purchaseToken: string}
        // From W3C docs on purchaseToken:
        //   purchaseToken is an abitrary token representing a purchase as generated by the serviceProvider.
        //   It is intended to be able to be used to verify the purchase by contacting the service provider
        //   directly (not part of the Digital Goods API).
        cacheSet(CacheKey.HouseholdStatus, HouseholdStatus.Subscribed);
        return;
      }
    }

    // defer to the server
    if (!householdToken) {
      cacheSet(CacheKey.HouseholdStatus, HouseholdStatus.Unknown);
      return;
    }
    const serverHouseholdStatus = await server_api.getServerHouseholdStatus({householdToken});
    // household must have been deleted from the server
    if (serverHouseholdStatus === HouseholdStatus.Unknown) {
      cacheRemove(CacheKey.BillingHouseholdToken);
      clearCachedHouseholdInfo();
    }
    if (householdStatus !== serverHouseholdStatus) {
      cacheSet(CacheKey.HouseholdStatus, serverHouseholdStatus);
    }
  } catch {}
}

export function requestIOSAppVersion() {
  if (isInsideAppleAppStoreApp()) {
    // @ts-ignore
    globalThis.receiveAppVersion = ({appVersion}: {appVersion: number})=> {
      cacheSet(CacheKey.IOSAppVersion, appVersion);
    }
    // @ts-ignore
    window.webkit?.messageHandlers?.getAppVersion?.postMessage({});
  }
}

// retrieves the client subscription info, caches it & updates the household if necessary
async function synchronizeClientSubscription() {
  if (isInsideGooglePlayApp()) {
    try {
      // TODO(hewitt): is the current household subscribed via Google Play -> how to connect the household ID?
      //               also how to extract the household token from the Google Play subscription
    } catch {
      console.log(`Error checking `)
    }
    return;
  }

  if (!isInsideAppleAppStoreApp()) {
    return;
  }

  let resolver: ((value: boolean | PromiseLike<boolean>) => void) | undefined;
  let appleSubscriptionInfo: AppleSubscriptionInfo | undefined;

  function parseAppleDateAsTimestamp(dateString?: string): number | undefined {
    // date string has format "2024-09-27 18:24:42 +0000"
    if (!dateString) {
      return;
    }
    const match = dateString.match(/^(?<year>\d+)-(?<month>\d+)-(?<day>\d+) .*/);
    if (!match || !match.groups) {
      console.log(`ERROR: could not parse Apple date "${dateString}"`);
      return;
    }
    const {year, month, day} = match.groups;
    return new Date(Number(year), Number(month) - 1, Number(day)).getTime();
  }

  // @ts-ignore
  globalThis.receiveSubscriptionInfo = (subscriptionInfo: AppleSubscriptionInfo) => {
    appleSubscriptionInfo = subscriptionInfo;
    resolver?.(true);
  }
  try {
    requestAppleSubscriptionInfo();

    await new Promise<boolean>((res) => {
      resolver = res;
      setTimeout(res, AppleSubscriptionInfoTimeoutMS);
    });

    if (!appleSubscriptionInfo || !appleSubscriptionInfo.expirationDate) {
      cacheRemove(CacheKey.ClientSubscription);
      return;
    }

    const subscription = {
      paymentProcessor: PaymentProcessor.Apple,
      appAccountToken: appleSubscriptionInfo.appAccountToken,
      subscriptionExpirationTimestamp: parseAppleDateAsTimestamp(appleSubscriptionInfo.expirationDate),
      productId: appleSubscriptionInfo.productId,
    };
    cacheSet(CacheKey.ClientSubscription, subscription);

    const {appAccountToken} = appleSubscriptionInfo;
    if (appAccountToken) {
      const householdToken = cacheGet(CacheKey.BillingHouseholdToken);
      if (householdToken === appAccountToken) {
        return;
      }

      // clear cached household info if a previous, different household token existed
      if (householdToken) {
        clearCachedHouseholdInfo();
      }

      cacheSet(CacheKey.BillingHouseholdToken, appAccountToken);
      const household = await server_api.getHousehold({householdToken: appAccountToken});
      if (household) {
        cacheSet(CacheKey.FamilyLastName, household.familyLastName);
        cacheSet(CacheKey.HouseholdEmail, household.householdEmail);
        await synchronizeChurchWithServer();
      }
    }
  } finally {
    // @ts-ignore
    delete globalThis.receiveSubscriptionInfo;
  }
}

export function clearCachedHouseholdInfo() {
  // does not clear household token
  cacheRemove(CacheKey.FamilyLastName);
  cacheRemove(CacheKey.HouseholdEmail);
  cacheRemove(CacheKey.Church)

  // TODO(hewitt): Remove 9/2024 - Update church based on billing id & church name
  cacheRemove(CacheKey.HouseholdChurchId);
  cacheRemove(CacheKey.HouseholdChurchName);
}

// TODO(hewitt): Remove 8/1/2024
export async function migrateUserChurchToHouseholdChurch() {
  if (cacheGet(CacheKey.HouseholdChurchId) || cacheGet(CacheKey.Church)) {
    return;
  }
  const user = cacheGet(CacheKey.User);
  if (!user || user === Guest) {
    return;
  }
  try {
    const church = await server_api.getUserChurch();
    if (!church || !church.id || !church.name || !church.location) {
      return;
    }
    cacheSet(CacheKey.Church, church);
    cacheSet(CacheKey.PushChurch, true);
    await synchronizeChurchWithServer();
  } catch { }
}
