import {useCallback, useEffect, useState} from "react";
import {Location} from './path';

export enum LocationNavigationOption {
  Ignore = 'ignore', // adds the entry to browser history, but does not trigger a page change
}

export function useLocation(): {
  location: Location,
  navigateTo: (url: string, option?: LocationNavigationOption) => void,
} {
  const [location, setLocation] = useState<Location>(() => getCurrentLocation());

  const onPopState = useCallback(() => {
    setLocation(getCurrentLocation());
  }, []);

  useEffect(() => {
    const listener = () => {
      setLocation(getCurrentLocation());
    };
    addListener(listener);
    window.addEventListener('popstate', onPopState);
    return () => {
      removeListener(listener);
      window.removeEventListener('popstate', onPopState);
    };
  }, [setLocation, onPopState]);

  const navigateTo = useCallback((url: string, option?: LocationNavigationOption) => {
    window.history.pushState(undefined, option === LocationNavigationOption.Ignore ? 'ignore' : '', url);
  }, []);

  return {location, navigateTo};
}

function getCurrentLocation(): Location {
  const {location} = window;
  return {
    pathname: location.pathname,
    search: location.search,
    hash: location.hash,
  }
}

const pushStateHandler = {
  apply: (target: any, thisArg: any, argArray: any[]) => {
    const output = target.apply(thisArg, argArray);
    if (argArray[1] !== 'ignore') {
      _callListeners();
    }
    return output;
  },
};

// browser does not provide an event for pushState, so we hook the call to generate our own event
export function monkeyPatchPushState() {
  window.history.pushState = new Proxy(window.history.pushState, pushStateHandler);
  window.history.replaceState = new Proxy(window.history.replaceState, pushStateHandler)
}

type Listener = () => void;

function addListener(listener: Listener) {
  _listeners.push(listener);
}

function removeListener(listener: Listener) {
  const index = _listeners.indexOf(listener);
  if (index !== -1) {
    _listeners.splice(index, 1);
  }
}

function _callListeners() {
  for (const listener of _listeners) {
    listener();
  }
}

const _listeners: Listener[] = [];
