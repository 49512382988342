import React, {useCallback} from 'react';
import './options.css'
import {ReactComponent as BackArrow} from './assets/arrow-previous-left-icon.svg';
import {ReactComponent as Add} from './assets/add.svg';
import {ReactComponent as Remove} from './assets/remove.svg';
import {Hymn} from './sequencer';
import {cacheGet, CacheKey, cacheSet} from './data/client_cache';
import {Favorite, TransposedSong} from './common/model';
import {useCache} from './data/use_cache';


interface Props {
  back: () => void;
  hymn: Hymn;
}

export const Options = ({back, hymn}: Props) => {
  const keys = ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "Ab", "A", "Bb", "B"]
  const [transpositions, setTranspostions] = useCache(CacheKey.KeyModulation);
  const initialKeyIndex = Math.max(0, keys.findIndex(key => key === hymn.key));

  const makeTransposition = useCallback((transposeValue: number = 0): TransposedSong => {
    return {songNumber: hymn.number, hymnalName: hymn.hymnal, transposeValue};
  }, [hymn]);

  const _equal = useCallback((lhs: Favorite, rhs: Favorite) => {
    return lhs.hymnalName === rhs.hymnalName && lhs.songNumber === rhs.songNumber;
  }, []);

  const getModulationValue = useCallback(() => {
    const transposition = transpositions?.filter(value => _equal(value, makeTransposition()))?.[0];
    return transposition?.transposeValue ?? 0;
  }, [_equal, makeTransposition, transpositions]);

  const keyIndex = (initialKeyIndex + getModulationValue() * 2 + 12) % 12;


  const handleSongIntroToggle = useCallback((e: any) => {
    let isChecked = e.target.checked;
    cacheSet(CacheKey.SongIntro, isChecked)
  }, []);

  function renderSongIntro() {
    return (
      <div className='option'>
        <div className="settingLabel">Song Introduction</div>
        <div style={{display: "flex"}}>
          <div className="settingMessage">Toggling Song Intro and exiting options will cause the player to reload</div>
          <div id='toggleContainer'>
            <input type="checkbox" id="settingsToggle" onChange={e => handleSongIntroToggle(e)} defaultChecked={cacheGet(CacheKey.SongIntro)}/>
          </div>
        </div>
      </div>
    )
  }

  // transpositions other than the current song
  function otherTranspositions() {
    return transpositions.filter(value => !_equal(value, makeTransposition()));
  }

  function reset() {
    setTranspostions([...otherTranspositions()]);
  }

  function modulateKey(delta: number) {
    const newModulationValue = Math.min(6, Math.max(-6, getModulationValue() + delta));
    setTranspostions([
      ...otherTranspositions(),
      ...(newModulationValue === 0 ? [] : [makeTransposition(newModulationValue)]),
    ]);
  }

  function renderKeyModulation() {
    const sign = getModulationValue() > 0 ? '+' : '';
    const modulationValue = getModulationValue().toFixed(1);
    return (
      <div className='option'>
        <div id="keyModulationSetting" style={{display: "flex"}}>
          <div className='settingLabel'>Transpose Key</div>
          <div id="modulationValue">{`${sign}${modulationValue}`}</div>
        </div>

        <div id="modulationContainer">
          <div className="control" id="left">
            <div onClick={() => modulateKey(-0.5)}>
              <Remove className="addSubtractIcon"/>
            </div>
          </div>

          <div id="key">
            <div style={{marginTop: '10px', fontWeight: 'bold'}}>
              {hymn.key && keys[keyIndex]}
            </div>
          </div>

          <div className="control" id="right">
            <div onClick={() => modulateKey(0.5)}>
              <Add className="addSubtractIcon"/>
            </div>

          </div>
          <button id="resetButton" onClick={() => reset()}>Reset</button>
        </div>
      </div>
    )
  }

  function handlePdfAutoDisplayToggle(e: any) {
    let isChecked = e.target.checked;
    cacheSet(CacheKey.PdfAutoDisplay, isChecked)
  }

  function renderPdfAutoDisplaySetting() {
    return (
      <div className='option'>
        <div className='settingLabel'>Display PDF on Play</div>
        <div style={{display: "flex"}}>
          <div className="settingMessage">With Automatic PDF enabled, the PDF will be displayed every time the play button is pressed</div>
          <div id='toggleContainer'>
            <input type="checkbox" id="settingsToggle" onChange={e => handlePdfAutoDisplayToggle(e)} defaultChecked={cacheGet(CacheKey.PdfAutoDisplay)}/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{width: '100%', display: "relative"}}>
      <div onClick={() => back()}>
        <BackArrow className="backArrow"/>
      </div>
      <div id="playerSettings">
        {renderSongIntro()}
        {renderPdfAutoDisplaySetting()}
        {renderKeyModulation()}

      </div>

    </div>
    )


};
