import React, {useEffect, useState} from 'react';
import {HymnalWithHymns, HymnsList} from './hymns_list';
import '../shared.css';
import {synchronizeChurchWithServer, useChurch} from '../data/use_church';
import {useCache} from '../data/use_cache';
import {cacheGet, CacheKey, cacheSet} from '../data/client_cache';
import {useLocation} from "../util/use_location";
import {Church, SongList, SongListEntry, SongListType} from '../common/model';
import fetch from 'node-fetch';
import {stringFromChurch} from '../common/server_api';
import {dateFromString} from '../common/date_string';


const todayTitle = 'Today';
const thisSundayTitle = 'This Sunday';
const lastSundayTitle = 'Last Sunday';
const nextSundayTitle = 'Next Sunday';

// todo: push this up to the this_week directory and return it from the server when the user is not signed in
const defaultThisWeekSongs: HymnalWithHymns[] = [
  {
    id: 0,
    name: 'This Sunday',
    hymns: [
      {hymnal: 'Cantus Christi (2020)', number: 408, title: 'A Mighty Fortress Is Our God'},
      {hymnal: 'Cantus Christi (2020)', number: 461, title: 'Amazing Grace!'},
      {hymnal: 'Cantus Christi (2020)', number: 447, title: 'And Can It Be That I Should Gain'},
      {hymnal: 'Cantus Christi (2020)', number: 317, title: 'Holy, Holy, Holy!'},
    ],
  }
];

function getHymnalsFromSongLists(householdToken?: string, songsForWeek?: SongList[]): HymnalWithHymns[] {
  // TODO(hewitt): remove songsForWeek check 8/2024 - should be householdToken only (see below as well)
  if (!householdToken && !songsForWeek) {
    return defaultThisWeekSongs;
  }
  if (songsForWeek === undefined) {
    return [];
  }
  const hymnals: HymnalWithHymns[] = songsForWeek.map((songList) => ({
    id: 0,
    name: getSongListName(songList),
    hymns: songList.songs.map(song => ({...song, title: song.title ?? 'Untitled'})), // b/c title is optional
  }));
  if (!songsForWeek.map(({date}) => date).includes(thisSundayDate())) {
    // keep hymn of the month first
    const containsHymnOfTheMonth = songsForWeek.length > 0 && songsForWeek[0].type === SongListType.HymnOfTheMonth;
    const start = containsHymnOfTheMonth ? 1 : 0;
    hymnals.splice(start, 0, {
      id: 0,
      name: getSundayTitle(thisSundayDate()),
      hymns: [],
      message: <div>Your music director has not yet added any songs for this week.</div>,
    })
  }
  return hymnals;
}

function getSongListName(songList: SongList) {
  const {name, type, date: dateStr, time, songs} = songList;
  if (type === SongListType.HymnOfTheMonth) {
    return getHymnOfTheMonthTitle(songs);
  }
  if (type === SongListType.WorshipService) {
    return getSundayTitle(dateStr);
  }
  const date = dateFromString(dateStr);
  const datePart = date ? ` - ${getDateAsShortString(date)}` : '';
  const shortTime = getTimeAsShortString(time);
  const timePart = shortTime ? ` @ ${shortTime}` : '';
  return `${name}${datePart}${timePart}`;
}

function getHymnOfTheMonthTitle(songs: SongListEntry[]) {
  const isPsalm = songs.length > 0 && (songs[0].psalm || songs[0].title?.toLowerCase().includes('psalm'));
  const prefix = isPsalm ? 'Psalm' : 'Hymn';
  return `${prefix} of the Month`;
}

function getSundayTitle(date: string) {
  const today = getDateAsLongString(new Date());
  switch (date) {
    case today:
      return todayTitle;
    case thisSundayDate():
      return thisSundayTitle;
    case lastSundayDate():
      return lastSundayTitle;
    case nextSundayDate():
      return nextSundayTitle;
    default:
      return date;
  }
}

function nextSundayDate() {
  const today = new Date();
  if (today.getDay() !== 0) {
    return 'no match';
  }
  const result = today;
  result.setDate(today.getDate() + 7);
  return getDateAsLongString(result);
}

function thisSundayDate() {
  const today = new Date();
  const result = today;
  result.setDate(today.getDate() + (today.getDay() > 0 ? 7 - today.getDay() : 0));
  return getDateAsLongString(result);
}

function lastSundayDate() {
  const today = new Date();
  const result = today;
  result.setDate(today.getDate() + (today.getDay() > 0 ? 7 - today.getDay() : 0) - 7);
  return getDateAsLongString(result);
}

function getDateAsLongString(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function getDateAsShortString(date: Date) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${months[date.getMonth()]} ${date.getDate()}`;
}

function getTimeAsShortString(time?: string): string | undefined {
  if (!time) {
    return;
  }
  const match = time.match(/T(?<hour>\d\d):(?<minute>\d\d):(?<second>\d\d)/);
  if (!match || !match.groups) {
    return;
  }
  const {hour: hourString, minute} = match.groups;
  const militaryHour = Number(hourString);
  const hour = militaryHour === 0 ? 12 : (militaryHour > 12 ? militaryHour - 12 : militaryHour);
  const ampm = militaryHour < 12 || militaryHour === 24 ? 'am' : 'pm';
  return `${hour}:${minute}${ampm}`;
}

export const ThisWeekPage = () => {
  const [householdToken] = useCache(CacheKey.BillingHouseholdToken);
  const {church} = useChurch();
  const [songsForWeek] = useCache(CacheKey.WeeklySongList);
  const [hymnals, setHymnals] = useState(() =>
    getHymnalsFromSongLists(householdToken, songsForWeek)
  );
  const {navigateTo} = useLocation();

  useEffect(() => {
    void synchronizeChurchWithServer();
  }, []);

  useEffect(() => {
    void synchronizeWeeklySongLists(church);
  }, [church]);

  useEffect(() => {
    setHymnals(getHymnalsFromSongLists(householdToken, songsForWeek));
  }, [householdToken, songsForWeek]);

  const musicLeaderEmailSubject = encodeURIComponent('Joining Sing Your Part');
  const musicLeaderEmailBody = encodeURIComponent(
    'Dear Worship Leader,\n\n' +
    'Please consider reaching out to the Sing Your Part team (support@crescendosw.com) ' +
    'to have our church added to their app so that we ' +
    'can know which songs to practice for Sunday worship each week.  ' +
    'This service is currently free to all churches.\n\n' +
    'Thank you!'
  );
  const mailToHRef = `mailto:?subject=${musicLeaderEmailSubject}&cc=support@crescendosw.com&body=${musicLeaderEmailBody}`;
  const askYourMusicLeader =
    <a className="inlineLink" href={mailToHRef}>
      ask your music leader
    </a>;

  let message: JSX.Element | undefined;
  // TODO(hewitt): remove songsForWeek check 8/2024 - should be householdToken only (see above as well)
  if (!householdToken && !songsForWeek) {
    message =
      <div>
        To view songs for your church, {askYourMusicLeader} to add your church to the app or <span className="inlineLink" onClick={() => navigateTo('/settings')}>sign in</span> if your church already participates.
      </div>;
  } else if (!church) {
    message = <div>To use this week, select a church in household <span className="inlineLink" onClick={() => navigateTo('/settings')}>settings</span>.</div>;
  } else if (songsForWeek === undefined) {
    message = <div>Downloading songs for this week...</div>;
  } else if (hymnals.length === 0) {
    message = <div>Your music director has not yet added songs for this Sunday.</div>;
  }
  return (
    <HymnsList
      title={church?.name ?? 'Songs By Week'}
      weeklySongGroups={hymnals}
      expandForThisWeek={true}
      message={message}
    />
  );
};

export async function synchronizeWeeklySongLists(church: Church | undefined) {
  if (!church) {
    cacheSet(CacheKey.WeeklySongList, undefined);
    return;
  }

  // ignore network errors
  try {
    const churchManifest = `../../this_week/${stringFromChurch(church)}.json`;
    const response = await fetch(churchManifest);
    const songsForWeek = await response.json();
    const previousSongsForWeek = cacheGet(CacheKey.WeeklySongList);

    // only update cache if something changed
    if (JSON.stringify(songsForWeek) !== JSON.stringify(previousSongsForWeek)) {
      cacheSet(CacheKey.WeeklySongList, songsForWeek);
    }
  } catch { }
}
